import { OrderData } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

/** Confirm the pick up location for customer collection */
async function confirmCollection(
  trackingNumber: string,
  pitstopUid: string
): Promise<ResponseModel> {
  const res = await fetchApi.post<ResponseModel>(
    apiRoutes.confirmCollection(trackingNumber),
    {
      pitstop_uid: pitstopUid,
    }
  )
  return res
}

export default confirmCollection
