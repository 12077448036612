import { HStack } from 'native-base'
import { Text } from 'native-base'
import React from 'react'
import styled from 'styled-components'

import CircleCheckIcon from '../../../../../core/Icons/CircleCheckIcon'

const Container = styled.div<{ $visible: boolean }>`
  transition: all 0.5s;
  display: none;
  ${({ $visible }) =>
    $visible
      ? `
    display: block;
  `
      : ''}
`

type Props = {
  visible: boolean
}

/**
 * Banner that behaves like a toast where it disappears in some time
 */
function SuccessBanner({ visible }: Props) {
  return (
    <Container $visible={visible}>
      <HStack
        p={3}
        mt={visible ? 6 : 0}
        bgColor={'rgb(25, 25, 25)'}
        borderRadius={2}
      >
        <HStack ml={3} space={4}>
          <CircleCheckIcon />
          <Text>Phone number saved</Text>
        </HStack>
      </HStack>
    </Container>
  )
}

export default SuccessBanner
