import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from './types'

function RelayMotifLogo({ size }: IconProps) {
  return (
    <Icon size={size} testID={'RelayMotifLogo'}>
      <Path
        d={
          'M14.882 0.047517C15.778 -0.166395 16.7703 0.362135 17.1155 1.22681C17.3608 1.79354 17.3151 2.47209 17.0102 3.00617C16.7081 3.55415 16.1347 3.93197 15.523 4.00628C14.5498 4.14172 13.5588 3.41039 13.3674 2.43389C13.107 1.39141 13.8486 0.250317 14.882 0.047517Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M20.9565 6.40724C21.966 6.11068 23.1121 6.82048 23.3438 7.85601C23.6404 8.88946 22.9399 10.0653 21.9106 10.3007C20.9046 10.5876 19.7741 9.8979 19.5253 8.87209C19.2157 7.8324 19.9115 6.63643 20.9565 6.40724Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M8.30985 7.41708C8.8696 7.25803 9.51 7.77962 9.4355 8.37899C9.47309 8.986 8.78895 9.47841 8.23398 9.26936C7.8328 9.1499 7.53823 8.73527 7.56693 8.30953C7.57308 7.8831 7.90388 7.50458 8.30985 7.41708Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M15.0761 7.42055C15.5381 7.28373 16.0602 7.60321 16.1969 8.06437C16.4047 8.63804 15.9235 9.31451 15.3262 9.3152C14.7965 9.34229 14.3256 8.84154 14.3448 8.30953C14.3721 7.8956 14.6756 7.515 15.0761 7.42055Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M20.9579 12.735C21.4848 12.6426 22.0575 12.7065 22.5025 13.0302C23.2823 13.5386 23.5898 14.645 23.203 15.4971C22.8948 16.2292 22.1279 16.741 21.3392 16.6931C20.3147 16.7 19.3832 15.7478 19.4406 14.6998C19.3818 13.7817 20.0878 12.9253 20.9579 12.735Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M1.61062 12.9093C2.48613 12.7142 3.44912 13.2351 3.79221 14.0747C4.04031 14.6422 4.00545 15.3291 3.69858 15.8673C3.43955 16.3417 2.97822 16.6945 2.46016 16.8257C1.87649 16.9896 1.22721 16.8292 0.756989 16.4493C0.325045 16.1139 0.0707996 15.5812 0.00723829 15.0387C-0.0809274 14.0546 0.646952 13.0746 1.61062 12.9093Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M7.9818 12.9475C8.97486 12.6288 10.1292 13.2823 10.3999 14.3005C10.7423 15.3221 10.0827 16.5354 9.05825 16.8063C8.03511 17.1459 6.84727 16.441 6.60054 15.3839C6.30666 14.3651 6.97029 13.2031 7.9818 12.9475Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M14.938 12.9059C15.8231 12.717 16.7949 13.2566 17.1223 14.1129C17.3533 14.6581 17.3164 15.3047 17.0362 15.8242C16.7669 16.3625 16.2311 16.7375 15.6543 16.8556C15.1704 16.9535 14.6571 16.8382 14.2422 16.5729C13.5588 16.1507 13.1801 15.2596 13.364 14.4665C13.513 13.6837 14.1664 13.0406 14.938 12.9059Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M27.6516 12.9093C28.6283 12.6871 29.689 13.367 29.9275 14.3519C30.2276 15.3804 29.5633 16.5604 28.5374 16.816C27.4972 17.1411 26.3052 16.3938 26.0995 15.3131C25.8248 14.256 26.5971 13.0878 27.6516 12.9093Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M20.9613 19.467C21.5265 19.3024 22.1382 19.4691 22.6187 19.7893C23.1292 20.1629 23.4122 20.8067 23.4142 21.4381C23.3855 21.8812 23.2399 22.3257 22.9529 22.666C22.5025 23.2278 21.7295 23.5265 21.0303 23.3549C20.3578 23.2202 19.7789 22.7028 19.565 22.0409C19.3169 21.3512 19.5021 20.5338 20.0031 20.0094C20.2539 19.7288 20.6059 19.5635 20.9613 19.467Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M8.24015 20.4706C8.76026 20.2858 9.35965 20.6873 9.4403 21.2311C9.58997 21.8888 8.86483 22.5562 8.23673 22.3062C7.75284 22.1673 7.44324 21.5992 7.60522 21.1109C7.69748 20.8074 7.9408 20.5623 8.24015 20.4706Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M15.0706 20.456C15.5155 20.3352 16.022 20.6025 16.1751 21.045C16.3938 21.536 16.0787 22.1541 15.5818 22.3048C15.0931 22.4986 14.5067 22.1374 14.383 21.6339C14.2279 21.1366 14.586 20.581 15.0706 20.456Z'
        }
        fill={'#D6EC83'}
      />
      <Path
        d={
          'M14.6783 26.0788C15.5299 25.7719 16.5455 26.1698 16.9945 26.9609C17.3588 27.5679 17.3649 28.368 17.0109 28.9812C16.7013 29.5535 16.0923 29.9209 15.4608 29.9932C14.5279 30.0758 13.5909 29.4 13.3797 28.468C13.1084 27.488 13.7262 26.3775 14.6783 26.0788Z'
        }
        fill={'#D6EC83'}
      />
    </Icon>
  )
}

export default RelayMotifLogo
