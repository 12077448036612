import Layout, {
  LAYOUT_HEIGHT_WITH_BREAKPOINTS,
  PADDING_WITH_BREAKPOINTS,
  SPACE_WITH_BREAKPOINTS,
} from './Layout'

export {
  LAYOUT_HEIGHT_WITH_BREAKPOINTS,
  PADDING_WITH_BREAKPOINTS,
  SPACE_WITH_BREAKPOINTS,
}
export default Layout
