import { getBaseUrl } from './apiRoutes'
import camelCaseObjectKeys from './camelCaseObjectKeys'

const POST_HEADERS_BASE = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}
const POST_FORM_DATAHEADERS_BASE = {
  Accept: 'application/json',
}

export class RelayError extends Error {
  constructor(message: string, public body?: object) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class UnauthorizedError extends RelayError {}

const camelCaseResponse = <T>(res: Record<string, unknown>): T =>
  camelCaseObjectKeys(res) as T

const fetchWithCamelCasedResponse = async <T>(
  path: string,
  option?: RequestInit
): Promise<T> => {
  const url = new URL(path, getBaseUrl())
  const response = await window.fetch(url, option)

  if (!response.ok) {
    const body =
      response.headers?.get('Content-Type') === 'application/json'
        ? await response.json()
        : undefined
    if (response.status === 401 || response.status === 403) {
      throw new UnauthorizedError(response.statusText, body)
    }
    throw new RelayError(response.statusText, body)
  }

  const responseJson = await response.json()
  return camelCaseResponse<T>(responseJson)
}

const fetchApi = {
  get: <T>(path: string, option?: RequestInit) =>
    fetchWithCamelCasedResponse<T>(path, option),
  post: <T>(path: string, data: unknown, option?: RequestInit): Promise<T> =>
    fetchWithCamelCasedResponse(path, {
      ...option,
      method: 'POST',
      headers: {
        ...option?.headers,
        ...POST_HEADERS_BASE,
      },
      body: JSON.stringify(data),
    }),
  postFormData: <T>(
    path: string,
    data: FormData,
    option?: RequestInit
  ): Promise<T> =>
    fetchWithCamelCasedResponse(path, {
      ...option,
      method: 'POST',
      headers: {
        ...option?.headers,
        ...POST_FORM_DATAHEADERS_BASE,
      },
      body: data,
    }),
}

export default fetchApi
