import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from './types'

function ChatIcon({ size = 28 }: IconProps) {
  return (
    <Icon size={size} color={'relay.volt'} viewBox={'0 -960 960 960'}>
      <Path
        d={
          'M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z'
        }
      />
    </Icon>
  )
}

export default ChatIcon
