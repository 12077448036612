import { apiRoutes } from '../../api/apiRoutes'
import { shipperRawGet } from './useShipperGet'

/**
 * Have the user's client automatically download a file named `filename`, with
 * contents `blob`.
 */
function downloadBlob(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

/**
 * Have the user's client download the label for the given barcode.
 */
export async function downloadLabel(barcode: string): Promise<void> {
  const blobResponse = await shipperRawGet(
    apiRoutes.shipperPortal.getShipmentLabel(barcode)
  )
  const blob = await blobResponse.blob()
  downloadBlob(blob, `${barcode}.pdf`)
}
