import dayjs from 'dayjs'
import { HStack, Link, Text, VStack } from 'native-base'
import React from 'react'

import { useOrderPageContext } from '../../OrderPageContext'
import { DeliveryStatusType, PitstopOpeningTime } from '../../types'

function PickupInstructions() {
  const {
    orderData: {
      deliveryStatus,
      pitstopName,
      pitstopOpeningTimes,
      pitstopAddress,
      pitstopPostcode,
      pitstopGeoLat,
      pitstopGeoLong,
    },
  } = useOrderPageContext()
  const href = `https://www.google.com/maps/search/?api=1&query=${pitstopGeoLat},${pitstopGeoLong}`

  // 0 is sunday, 1 is monday
  const today = dayjs().format('d')
  // 7 is sunday, 1 is monday
  const getOpeningTime = (pitstopOpeningTimes: PitstopOpeningTime[]) => {
    if (today === '0') {
      return pitstopOpeningTimes.find(
        (openingTime) => openingTime.dayOfWeek === 7
      )
    }

    return pitstopOpeningTimes.find(
      (openingTime) => openingTime.dayOfWeek?.toString() === today
    )
  }

  const getHoursOfToday = () => {
    if (!pitstopOpeningTimes) {
      return ''
    }

    const openingTime = getOpeningTime(pitstopOpeningTimes)

    return openingTime ? `${openingTime.opensAt} - ${openingTime.closesAt}` : ''
  }

  return (
    <VStack space={10}>
      {deliveryStatus === DeliveryStatusType.CollectionExpiredReturnToSender ? (
        <Text color={'danger.500'} textAlign={'center'}>
          No longer available for pick up
        </Text>
      ) : (
        <Text color={'relay.moss'}>
          You must show the QR code that was emailed to you at pick up
        </Text>
      )}
      <HStack space={3} justifyContent={'space-between'}>
        <VStack
          space={1}
          width={'50%'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <Text fontWeight={'bold'}>Shop</Text>
          <Text>{pitstopName}</Text>
          <Text>{pitstopAddress}</Text>
          <Text>{pitstopPostcode}</Text>
        </VStack>
        <VStack space={1} width={'50%'} alignItems={'center'}>
          <Text fontWeight={'bold'}>Today&apos;s Hours</Text>
          <Text>{getHoursOfToday()}</Text>
          <Link isUnderlined={false} href={href} mt={3} isExternal>
            Google Maps
          </Link>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PickupInstructions
