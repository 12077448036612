import { OrderData, SafePlaceType } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

async function updatePreferredSafePlace(
  trackingNumber: string,
  action: 'set' | 'clear',
  data?: {
    safePlaceType: SafePlaceType
    neighbourDoorNumber: string | null
  }
): Promise<ResponseModel> {
  const { safePlaceType, neighbourDoorNumber } = data ?? {}
  const response = await fetchApi.post<ResponseModel>(
    apiRoutes.updatePreferredSafePlace(trackingNumber),
    {
      action,
      safe_place_type: safePlaceType,
      neighbour_door_number: neighbourDoorNumber,
    }
  )
  return response
}

export default updatePreferredSafePlace
