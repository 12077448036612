import { Box, Button, HStack, Stack, Text } from 'native-base'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useOrderPageContext } from '../../OrderPageContext'
import { SafePlace as SafePlaceType } from '../../types'
import usePrevious from '../shared/usePrevious'
import fallbackSafePlaceList, {
  getSafePlace,
  NEIGHBOUR_ID,
  neighbourMaxCharacterLimit,
  safePlaceIcons,
} from './safePlaceList'
import SafePlaceListRadioGroup from './SafePlaceListRadioGroup'
import useSafePlace from './useSafePlace'

const safePlceIconSize = 50
const SafePlaceIcon = styled.img`
  width: ${safePlceIconSize}px;
  height: ${safePlceIconSize}px;
`

const checkboxText =
  'By selecting this, you take responsibility for the loss or damage of any item delivered here'

const SafePlace: React.FC = () => {
  const {
    orderData: { safePlacesList: safePlacesListFromBE, safePlacePreference },
  } = useOrderPageContext()
  const prevSafePlacePreference =
    usePrevious<typeof safePlacePreference>(safePlacePreference)

  const {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
    clearSafePlace,
  } = useSafePlace()

  const [isEditMode, setIsEditMode] = useState<boolean>(!selectedPlace)

  const isNeighbourSelected = Boolean(selectedPlace?.id === NEIGHBOUR_ID)

  // if BE sends empty array, we assume it's intentional and will use it still
  const safePlacesList = safePlacesListFromBE ?? fallbackSafePlaceList
  const showUpdateButton = !isEditMode && !!selectedPlace

  const handleChangeSavePlace = (safePlaceId: string) => {
    const safePlace = safePlacesList.find(
      (place: SafePlaceType) => place.id === safePlaceId
    )
    setSelectedPlace(safePlace ?? null)
    if (safePlace?.id !== NEIGHBOUR_ID) {
      setNeighbourDoorNumber('')
    }
  }

  const handleSaveSafePlace = async () => {
    if (selectedPlace) {
      await onSaveSafePlace()
      setIsEditMode(false)
    }
  }

  useEffect(() => {
    // safe place could be updated somewhere else. In this case we want to
    // honer that first
    if (
      safePlacePreference?.safePlaceType !==
      prevSafePlacePreference?.safePlaceType
    ) {
      setSelectedPlace(getSafePlace(safePlacePreference))
      setIsEditMode(false)
    }
  }, [prevSafePlacePreference, safePlacePreference, setSelectedPlace])

  return (
    <Stack mb={6}>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={'bold'} py={2}>
          Your preferred safe place
        </Text>
        {isEditMode && !!selectedPlace && (
          <Button
            isLoading={loading}
            onPress={() => {
              setSelectedPlace(null)
              clearSafePlace()
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
          >
            Clear
          </Button>
        )}
        {showUpdateButton && (
          <Button
            onPress={() => {
              setIsEditMode(true)
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
          >
            Update
          </Button>
        )}
      </HStack>
      <Stack mt={4}>
        {!isEditMode && selectedPlace?.label ? (
          <HStack alignItems={'center'} space={2}>
            {safePlaceIcons[selectedPlace.id] && (
              <SafePlaceIcon
                src={safePlaceIcons[selectedPlace.id]}
                alt={selectedPlace.label}
              />
            )}
            <Text fontSize={'lg'}>{selectedPlace?.label}</Text>
            {isNeighbourSelected && <Text>{neighbourDoorNumber}</Text>}
          </HStack>
        ) : (
          <>
            <SafePlaceListRadioGroup
              selectedPlace={selectedPlace}
              safePlacesList={safePlacesList}
              loading={loading}
              neighbourDoorNumber={neighbourDoorNumber}
              onChangeSelectedSafePlace={(id: string) =>
                handleChangeSavePlace(id)
              }
              onChangeNeighbourDoorNumber={(text: string) =>
                setNeighbourDoorNumber(
                  text.slice(0, neighbourMaxCharacterLimit)
                )
              }
            />
            <Box backgroundColor={'relay.gray'} p={3} my={2}>
              <Text>{checkboxText}</Text>
            </Box>
            <Button
              bgColor={'relay.gray'}
              borderRadius={'full'}
              _text={{
                fontWeight: 'extraBold',
                color: 'relay.volt',
              }}
              alignSelf={['stretch', 'stretch', 'flex-start']}
              px={6}
              onPress={handleSaveSafePlace}
              my={4}
              isDisabled={
                !selectedPlace || (isNeighbourSelected && !neighbourDoorNumber)
              }
              isLoading={loading}
            >
              Save safe place
            </Button>
            {!!error && <Text color={'danger.500'}>{error}</Text>}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default SafePlace
