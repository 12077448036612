import { OrderData } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

async function getShipment(
  trackingNumber: string,
  postcode?: string
): Promise<ResponseModel> {
  if (postcode) {
    const res = await fetchApi.get<ResponseModel>(
      apiRoutes.getShipmentWithPostcode(trackingNumber, postcode)
    )
    return res
  }

  const res = await fetchApi.get<ResponseModel>(
    apiRoutes.getShipment(trackingNumber)
  )
  return res
}

export default getShipment
