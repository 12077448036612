import { OrderData } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

/** Update customer's phone number */
async function updatePhoneNumber(
  trackingNumber: string,
  telephone: string
): Promise<ResponseModel> {
  const res = await fetchApi.post<ResponseModel>(
    apiRoutes.updatePhoneNumber(trackingNumber),
    {
      end_customer_contact_phone: telephone,
    }
  )
  return res
}

export default updatePhoneNumber
