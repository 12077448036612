export const relayColors = {
  volt: '#d6ec83',
  moss: '#93a896',
  gray: '#303030',
  darkGray: '#191919',
  slate: '#3a3947',
  black: '#000000',
  white: '#ffffff',
  textPrimary: '#ffffff',
  textSecondary: '#FFFFFF80',
  error: '#DD3333',
}

const relayFont = {
  heading: 'Helvetica',
  body: 'Helvetica',
  mono: 'Helvetica',
}

export const relayTheme = {
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    relay: relayColors,
  },
  fonts: relayFont,
  components: {
    Button: {
      defaultProps: {
        bgColor: 'relay.volt',
        _text: {
          color: 'relay.black',
        },
      },
    },
    Text: {
      defaultProps: {
        color: 'relay.textPrimary',
        fontSize: 'md',
      },
    },
    Link: {
      defaultProps: {
        _text: {
          color: 'relay.volt',
        },
      },
    },
    Radio: {
      defaultProps: {
        _checked: {
          borderColor: 'relay.volt',
          _icon: {
            color: 'relay.volt',
          },
          _hover: {
            borderColor: 'relay.volt',
            _icon: { color: 'relay.volt' },
          },
          _pressed: {
            borderColor: 'relay.volt',
            _icon: { color: 'relay.volt' },
          },
        },
      },
    },
    Checkbox: {
      defaultProps: {
        _checked: {
          borderColor: 'relay.volt',
          bg: 'relay.volt',
          _hover: {
            borderColor: 'relay.volt',
            bg: 'relay.volt',
          },
        },
      },
    },
  },
}
