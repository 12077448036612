import {
  Loader as GoogleMapsApiLoader,
  LoaderOptions as GoogleMapsApiLoaderOptions,
} from '@googlemaps/js-api-loader'
import { ReactElement, useEffect, useState } from 'react'

export enum GoogleMapsLoaderStatus {
  LOADING = 'LOADING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

interface GoogleMapsLoaderProps extends GoogleMapsApiLoaderOptions {
  render: (status: GoogleMapsLoaderStatus) => ReactElement
}

/**
 * A simple wrapper to load google maps scripts, and render components
 * as it's passed in via `render` based on the status.
 */
function GoogleMapsLoader({ render, ...options }: GoogleMapsLoaderProps) {
  const [status, setStatus] = useState(GoogleMapsLoaderStatus.LOADING)

  useEffect(() => {
    const loader = new GoogleMapsApiLoader(options)

    setStatus(GoogleMapsLoaderStatus.LOADING)

    loader.importLibrary('maps').then(
      () => setStatus(GoogleMapsLoaderStatus.SUCCESS),
      () => setStatus(GoogleMapsLoaderStatus.FAILURE)
    )
    // This is intentional, so that we don't load google maps scripts for more
    // than once. This is the official recommendation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return render(status)
}

export default GoogleMapsLoader
