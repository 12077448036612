import { useCallback, useEffect, useState } from 'react'

import { getBaseUrl } from '../../api/apiRoutes'
import fetchApi, { UnauthorizedError } from '../../api/fetchApi'
import { AUTH_TOKEN_KEY } from './types'
import { withUnauthorizedRedirect } from './withUnauthorizedRedirect'

/**
 * Make a GET request authenticated by the locally-stored authentication token,
 * and return the result as a raw Response object.  Unsuccessful responses will
 * cause an exception, so the returned response will always be successful.
 */
export async function shipperRawGet(path: string): Promise<Response> {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  if (!token) {
    throw new UnauthorizedError('No token')
  }
  const url = new URL(path, getBaseUrl())
  const response = await window.fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      throw new UnauthorizedError(response.statusText)
    }
    throw new Error(response.statusText)
  }
  return response
}

/**
 * Make a GET request authenticated by the locally-stored authentication token,
 * and return the result as a parsed JSON object with camel-cased keys.
 */
export async function shipperGet<T>(endpoint: string): Promise<T> {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  if (!token) {
    throw new UnauthorizedError('No token')
  }
  return await fetchApi.get<T>(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

type State<T> = {
  loading: boolean
  error: Error | undefined
  data: T | undefined
}

export function useShipperGet<T>(endpoint: string) {
  const [state, setState] = useState<State<T>>({
    loading: true,
    error: undefined,
    data: undefined,
  })

  const fetch = useCallback(async (endpoint: string) => {
    try {
      setState({
        loading: true,
        error: undefined,
        data: undefined,
      })
      const data = await withUnauthorizedRedirect(() => shipperGet<T>(endpoint))
      setState({
        loading: false,
        error: undefined,
        data,
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        setState({
          loading: false,
          error,
          data: undefined,
        })
      } else {
        setState({
          loading: false,
          error: new Error('Unknown error'),
          data: undefined,
        })
      }
    }
  }, [])

  useEffect(() => {
    fetch(endpoint)
  }, [fetch, endpoint])

  return state
}

export default useShipperGet
