import React, { useState } from 'react'

import PhoneNumberModal from './PhoneNumberModal'
import SuccessBanner from './SuccessBanner'

const TIMEOUT = 5000

/**
 * Prompt customer with a modal for entering phone number, and then display
 * an inline success banner which disappears after 5 seconds
 */
function PhoneNumberPrompt() {
  const [showBanner, setShowBanner] = useState<boolean>(false)

  return (
    <>
      <PhoneNumberModal
        onSuccess={() => {
          setShowBanner(true)
          setTimeout(() => {
            setShowBanner(false)
          }, TIMEOUT)
        }}
      />
      <SuccessBanner visible={showBanner} />
    </>
  )
}

export default PhoneNumberPrompt
