import FeatureFlag from './FeatureFlag'
import launchDarklyConfig from './launchDarkly'

export default FeatureFlag
export { launchDarklyConfig }

export {
  BackendFeatureFlagsContextProvider,
  useBackendFeatureFlagsContext,
} from './BackendFeatureFlags'
export type { BackendFeatureFlags } from './BackendFeatureFlags'
