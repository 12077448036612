import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import React, { Fragment, PropsWithChildren, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import launchDarklyConfig from './launchDarkly'

export default function FeatureFlag({ children }: PropsWithChildren) {
  const { orderId } = useParams()
  const ldClient = useLDClient()
  useEffect(() => {
    if (ldClient && orderId) {
      ldClient.identify({
        kind: 'user',
        // Uncomment if we want to be able to roll out according
        // to shipment barcode -- note that this requires paying
        // LaunchDarkly $$$ for more users!
        //
        // Remember to also comment "anonymous: true" below.
        //
        // key: orderId,
        anonymous: true,
      })
    }
  }, [orderId, ldClient])
  return <Fragment>{children}</Fragment>
}

export async function asyncWithFeatureFlagProvider() {
  const LDProvider = await asyncWithLDProvider(launchDarklyConfig)
  function FeatureFlagProvider({ children }: PropsWithChildren) {
    return <LDProvider>{children}</LDProvider>
  }
  return FeatureFlagProvider
}
