import { VStack } from 'native-base'
import React from 'react'

import TimelineItem, { TimelineItemType } from './TimelineItem'

type TimelineProps = {
  items: TimelineItemType[]
}

/** Renders an array of items with the vertical timeline style */
function Timeline({ items }: TimelineProps) {
  return (
    <VStack marginTop={2}>
      {items.map((item: TimelineItemType, index: number) => (
        <TimelineItem
          key={item.title}
          isLast={index === items.length - 1}
          {...item}
        />
      ))}
    </VStack>
  )
}

export default Timeline
