import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import getReturn from '../../api/getReturn'
import getShipment from '../../api/getShipment'
import mockOrderData, { mockReturnData } from './__mocks__/orderData'
import {
  isOrderDataType,
  isReturnDataType,
  OrderData,
  OrderType,
} from './types'

const RETURN_ORDER_ID_PREFIX_LIST = ['RET', 'VRET']

// 30 minutes
const POLLING_INTERVAL = 30 * 60 * 1000

const DEFAULT_EMPTY_DATA = {
  isLoading: false,
  orderData: null,
  error: '',
}

/**
 * Fetch to get shipment data by id, and start interval to poll every 30 mins
 */
export function useGetShipment(
  orderId: string,
  { skip, shouldUseMockData }: { skip: boolean; shouldUseMockData: boolean }
): {
  isLoading: boolean
  orderData: OrderData | null
  error: string
} {
  const [searchParams] = useSearchParams()
  const postcode = searchParams.get('postcode')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [orderData, setOrderData] = useState<OrderData | null>(null)
  const [error, setError] = useState<string>('')

  const fetchGetShipment = useCallback(async () => {
    setIsLoading(true)

    try {
      const rawLoaderdata =
        postcode != null
          ? await getShipment(orderId, postcode)
          : await getShipment(orderId)
      setError('')
      setOrderData(isOrderDataType(rawLoaderdata) ? rawLoaderdata : null)
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((err as any)?.message ?? String(err))
    }
    setIsLoading(false)
  }, [orderId, postcode])

  useEffect(() => {
    // skip fetching for shipment data
    if (shouldUseMockData || skip) {
      return
    }

    fetchGetShipment()
    const pollingInterval = setInterval(fetchGetShipment, POLLING_INTERVAL)

    return () => {
      clearInterval(pollingInterval)
    }
  }, [fetchGetShipment, shouldUseMockData, skip])

  if (skip) {
    return DEFAULT_EMPTY_DATA
  }

  if (shouldUseMockData) {
    return {
      isLoading: false,
      orderData: mockOrderData,
      error: '',
    }
  }

  return {
    isLoading,
    orderData,
    error,
  }
}

export function useGetReturn(
  orderId: string,
  { skip, shouldUseMockData }: { skip: boolean; shouldUseMockData: boolean }
) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [orderData, setOrderData] = useState<OrderData | null>(null)
  const [error, setError] = useState<string>('')

  const fetchReturn = useCallback(async () => {
    setIsLoading(true)

    try {
      const rawData = await getReturn(orderId)
      setError('')
      setOrderData(isReturnDataType(rawData) ? rawData : null)
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((err as any)?.message ?? String(err))
    } finally {
      setIsLoading(false)
    }
  }, [orderId])

  useEffect(() => {
    if (shouldUseMockData || skip) {
      return
    }

    fetchReturn()
    const pollingInterval = setInterval(fetchReturn, POLLING_INTERVAL)

    return () => {
      clearInterval(pollingInterval)
    }
  }, [orderId, shouldUseMockData, skip, fetchReturn])

  if (skip) {
    return DEFAULT_EMPTY_DATA
  }

  if (shouldUseMockData) {
    return {
      isLoading: false,
      orderData: mockReturnData,
      error: '',
    }
  }

  return {
    isLoading,
    orderData,
    error,
  }
}

// only export for tests
export function getOrderTypeById(orderId?: string): OrderType {
  if (!orderId) {
    // default to shipment type
    return OrderType.Shipment
  }

  const orderIdUpperCase = orderId.toUpperCase()
  const isReturnOrder = RETURN_ORDER_ID_PREFIX_LIST.some((prefix) =>
    orderIdUpperCase.startsWith(prefix)
  )

  return isReturnOrder ? OrderType.Return : OrderType.Shipment
}

export function useGetOrderData(): {
  isLoading: boolean
  orderData: OrderData | null
  error: string
  orderType: OrderType
} {
  const { orderId } = useParams()
  const orderType = getOrderTypeById(orderId)

  const [searchParams] = useSearchParams()
  const shouldUseMockData = searchParams.get('useMockData') === 'true'

  const shipmentData = useGetShipment(orderId ?? '', {
    shouldUseMockData,
    skip: orderType !== OrderType.Shipment,
  })
  const returnData = useGetReturn(orderId ?? '', {
    shouldUseMockData,
    skip: orderType !== OrderType.Return,
  })

  const data = orderType === OrderType.Return ? returnData : shipmentData

  return {
    ...data,
    orderType,
  }
}
