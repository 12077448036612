import dayjs from 'dayjs'
import {
  Accordion as NativeBaseAccordion,
  Box,
  Button,
  HStack,
  VStack,
} from 'native-base'
import { Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import LocationPinIcon from '../../core/Icons/LocationPinIcon'
import ParcelIcon from '../../core/Icons/ParcelIcon'
import Layout from '../../core/Layout'
import Map, { GoogleMapsMarker } from '../../core/Map'
import locationPinIcon from '../../core/Map/locationPinIcon'
import walkIcon from '../../core/Map/walkIcon'
import Timeline from '../../core/Timeline'
import { relayColors } from '../../theme'
import { getAddressText } from '../utils'
import { mockReturnDataV2 } from './__mocks__/orderData'
import { getProgressValue, Header } from './OrderTracking'
import AccordionItem from './OrderTracking/Accordion/AccordionItem'
import { Event, ReturnOrderData } from './types'

type ReturnOrderProps = {
  orderData: ReturnOrderData
}

function ReturnOrderV2({ orderData }: ReturnOrderProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    deliveryStatus,
    statusDisplayString,
    expectedDeliveryTimeDisplayString,
    events,
    destination,
    shipperLogo,
    brandName,
    returnLocation,
    pickupDeadline,
  } = orderData

  const getMapBounds = () => {
    if (!destination) {
      return []
    }

    const bounds = [destination.coordinates]

    if (returnLocation?.address) {
      bounds.push({
        lat: returnLocation.address.lat,
        lon: returnLocation.address.lon,
      })
    }

    return bounds
  }

  const now = dayjs()
  const deadline = dayjs(pickupDeadline)
  const isWithin24Hours =
    now.isBefore(deadline) && deadline.diff(now, 'h') <= 24

  return (
    <Layout
      renderMap={
        destination
          ? () => (
              <Box flex={['none', 'none', 1]}>
                <Map
                  bounds={getMapBounds()}
                  shipperLogo={shipperLogo}
                  brandName={brandName}
                  // hiding default marker because we are rendering the marker
                  // with different icon
                  hideDefaultMarker
                >
                  <GoogleMapsMarker
                    position={{
                      lat: destination.coordinates.lat,
                      lon: destination.coordinates.lon,
                    }}
                    icon={{
                      url: walkIcon,
                    }}
                  />
                  {!!returnLocation?.address && (
                    <GoogleMapsMarker
                      position={{
                        lat: returnLocation.address.lat,
                        lon: returnLocation.address.lon,
                      }}
                      icon={{
                        url: locationPinIcon,
                      }}
                    />
                  )}
                </Map>
              </Box>
            )
          : undefined
      }
    >
      <Header
        label={isWithin24Hours ? null : 'Return started'}
        title={expectedDeliveryTimeDisplayString ?? ''}
        subtitle={statusDisplayString}
        progressBarValue={getProgressValue(deliveryStatus)}
        badgeText={
          isWithin24Hours
            ? {
                label: 'Only 24 hours left to return',
                type: 'warn',
              }
            : undefined
        }
        trailingIcon={
          <Box
            borderRadius={'full'}
            borderColor={'relay.volt'}
            borderWidth={'3px'}
            p={1}
          >
            <ParcelIcon size={6} color={relayColors.volt} />
          </Box>
        }
      />
      <NativeBaseAccordion bgColor={'none'} borderWidth={0} defaultIndex={[0]}>
        <AccordionItem index={1} title={'Drop-off Location'}>
          {!returnLocation && (
            <HStack>
              <Button
                onPress={() =>
                  navigate(
                    `/return-locations?goBack=${encodeURIComponent(
                      'Return to Tracking'
                    )}`,
                    {
                      state: {
                        from: `${location.pathname}${location.search}`,
                      },
                    }
                  )
                }
              >
                Find your nearest drop-off location
              </Button>
            </HStack>
          )}
          {!!returnLocation && (
            <VStack>
              <HStack space={3} alignItems={'center'}>
                <LocationPinIcon />
                <VStack>
                  {returnLocation.address.organisation && (
                    <Text>{returnLocation.address.organisation}</Text>
                  )}
                  {returnLocation.address && (
                    <Text opacity={0.9}>
                      {getAddressText(returnLocation.address)}
                    </Text>
                  )}
                </VStack>
              </HStack>
            </VStack>
          )}
        </AccordionItem>
        <AccordionItem
          index={0}
          title={'Parcel Journey'}
          leadingIcon={<ParcelIcon />}
        >
          <Timeline
            items={events.map(
              ({
                statusDisplaySlug,
                timestamp,
                imageUrl,
                images,
                courierNote,
              }: Event) => {
                return {
                  title: statusDisplaySlug,
                  subtitle: dayjs(timestamp).format('MMMM DD, HH:mm'),
                  imgUrl: imageUrl,
                  images,
                  description: courierNote,
                }
              }
            )}
          />
        </AccordionItem>
      </NativeBaseAccordion>
    </Layout>
  )
}

function ReturnOrderV2Container({ orderData }: ReturnOrderProps) {
  const [searchParams] = useSearchParams()
  const shouldUseMockData = searchParams.get('useMockData') === 'true'

  return shouldUseMockData ? (
    // this is only temporary for local dev. we should get rid of this once
    // we are fully on v2 (no more v1)
    <ReturnOrderV2 orderData={mockReturnDataV2} />
  ) : (
    <ReturnOrderV2 orderData={orderData} />
  )
}

export default ReturnOrderV2Container
