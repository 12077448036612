import { Box, Center, Spinner, Text, VStack } from 'native-base'
import React from 'react'

import { ReturnLocation } from '../types'
import LocationCard from './LocationCard'

type ReturnLocationsListProps = {
  loading: boolean
  error: string
  locations: ReturnLocation[]
}

function ReturnLocationsList({
  loading,
  error,
  locations,
}: ReturnLocationsListProps) {
  if (loading) {
    return (
      <Center h={'100%'}>
        <Spinner size={'lg'} color={'relay.volt'} />
      </Center>
    )
  }

  if (error) {
    return (
      <VStack h={'100%'} justifyContent={'center'} alignItems={'center'}>
        <Text fontSize={'xl'}>{error}</Text>
      </VStack>
    )
  }

  return (
    <Box flex={'1'}>
      {locations.length === 0 ? (
        <Text>No Results.</Text>
      ) : (
        <>
          {locations.map((location: ReturnLocation, index: number) => (
            <LocationCard
              key={location.code}
              data={location}
              order={index + 1}
            />
          ))}
          {/* only for adding spacing */}
          <Box py={8} />
        </>
      )}
    </Box>
  )
}

export default ReturnLocationsList
