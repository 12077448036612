import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from './types'

function PitstopIcon({ size = 36 }: IconProps) {
  return (
    <Icon size={size} viewBox={'0 0 42 52'}>
      <g filter={'url(#filter0_d_1070_40051)'}>
        <Path
          d={
            'M36.5 17.25C36.5 20.6157 34.6597 24.7586 31.951 29.0616C29.2765 33.3103 25.9232 37.4621 23.1966 40.8189C22.101 42.1679 19.899 42.1679 18.8034 40.8189C16.0768 37.4621 12.7235 33.3103 10.049 29.0616C7.3403 24.7586 5.5 20.6157 5.5 17.25C5.5 8.5308 12.4602 1.5 21 1.5C29.5398 1.5 36.5 8.5308 36.5 17.25Z'
          }
          fill={'#D6EC83'}
          stroke={'white'}
          strokeWidth={'3'}
        />
        <g clipPath={'url(#clip0_1070_40051)'}>
          <Path
            d={
              'M18.3333 12L18.3333 13.3333M18.3333 24L18.3333 18.6667M18.3333 13.3333L26.3333 13.3333L24.9999 16L26.3333 18.6667L18.3333 18.6667M18.3333 13.3333L18.3333 18.6667'
            }
            stroke={'black'}
            strokeWidth={'1.5'}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
        </g>
      </g>
      <defs>
        <filter
          id={'filter0_d_1070_40051'}
          x={'0'}
          y={'0'}
          width={'43'}
          height={'54'}
          filterUnits={'userSpaceOnUse'}
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
          <feColorMatrix
            in={'SourceAlpha'}
            type={'matrix'}
            values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
            result={'hardAlpha'}
          />
          <feOffset dy={'4'} />
          <feGaussianBlur stdDeviation={'2'} />
          <feComposite in2={'hardAlpha'} operator={'out'} />
          <feColorMatrix
            type={'matrix'}
            values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'}
          />
          <feBlend
            mode={'normal'}
            in2={'BackgroundImageFix'}
            result={'effect1_dropShadow_1070_40051'}
          />
          <feBlend
            mode={'normal'}
            in={'SourceGraphic'}
            in2={'effect1_dropShadow_1070_40051'}
            result={'shape'}
          />
        </filter>
        <clipPath id={'clip0_1070_40051'}>
          <rect
            width={'16'}
            height={'16'}
            fill={'white'}
            transform={'translate(13 10)'}
          />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default PitstopIcon
