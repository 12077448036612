import { useState } from 'react'

import updateNoteToCourier from '../../../../api/updateNoteToCourier'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later'

function useUpdateNoteToCourier(
  trackingNumber: string,
  onSuccess: (note: string) => void
): {
  isLoading: boolean
  error: string
  onUpdateNoteToCourier: (note: string) => Promise<void>
} {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const handleUpdateNoteToCourier = (note: string) => {
    setIsLoading(true)

    return updateNoteToCourier(trackingNumber, note)
      .then(({ courierNote }) => {
        onSuccess(courierNote)
        setError('')
      })
      .catch((e) => {
        setError(e?.message ?? DEFAULT_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    isLoading,
    error,
    onUpdateNoteToCourier: handleUpdateNoteToCourier,
  }
}

export default useUpdateNoteToCourier
