import React from 'react'
import { styled } from 'styled-components'

import RelayWordmarkLogo from '../Icons/RelayWordmarkLogo'

const LogoContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 24px;
`

function RelayLogo() {
  return (
    <LogoContainer data-testid={'RelayLogo'}>
      <RelayWordmarkLogo />
    </LogoContainer>
  )
}

export default RelayLogo
