import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

function useGoBackButton() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [searchParams] = useSearchParams()
  const goBackButton = searchParams.get('goBack')

  // We only want to get the value on initial load
  const [from] = useState<string>(state?.from || '')

  return goBackButton && from
    ? {
        text: decodeURIComponent(goBackButton),
        goBack: () =>
          navigate(from, {
            relative: 'path',
          }),
      }
    : null
}

export default useGoBackButton
