import camelCase from 'lodash.camelcase'
import isPlainObject from 'lodash.isplainobject'

type UnknownObject = Record<string, unknown>

/** Recursively transform array */
function transformArrayKeys(arr: unknown[]): unknown[] {
  return arr.map((item: unknown) => {
    if (Array.isArray(item)) {
      return transformArrayKeys(item)
    }

    if (isPlainObject(item)) {
      return camelCaseObjectKeys(item)
    }

    return item
  })
}

/** Recursively transform object keys to camel case */
function camelCaseObjectKeys(input: unknown) {
  if (!input || typeof input === 'string' || typeof input === 'number') {
    return input
  }

  if (Array.isArray(input)) {
    return transformArrayKeys(input)
  }

  return Object.keys(input).reduce((curr: UnknownObject, key: string) => {
    const value = (input as UnknownObject)[key]
    const newKey = camelCase(key)
    const newState: UnknownObject = { ...curr }

    if (Array.isArray(value)) {
      newState[newKey] = transformArrayKeys(value)
    } else if (isPlainObject(value)) {
      newState[newKey] = camelCaseObjectKeys(value)
    } else {
      newState[newKey] = value
    }

    return newState
  }, {})
}

export default camelCaseObjectKeys
