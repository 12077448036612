import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from 'dayjs/plugin/utc'
import { extendTheme, NativeBaseProvider } from 'native-base'
import React from 'react'
import { RouterProvider } from 'react-router-dom'

import router from './router'
import { relayTheme } from './theme'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

const europeLondonTz = 'Europe/London'
dayjs.tz.setDefault(europeLondonTz)

const theme = extendTheme(relayTheme)

type CustomThemeType = typeof theme
declare module 'native-base' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ICustomTheme extends CustomThemeType {}
}

function App() {
  return (
    <NativeBaseProvider theme={theme}>
      <RouterProvider router={router} />
    </NativeBaseProvider>
  )
}

export default App
