import { OrderData } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

async function getReturn(trackingNumber: string): Promise<ResponseModel> {
  const res = await fetchApi.get<ResponseModel>(
    apiRoutes.getReturn(trackingNumber)
  )
  return res
}

export default getReturn
