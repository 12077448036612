export type AuthResponse = {
  status: string
  token: string
}

export type CreateShipmentResponse = {
  barcode: string
}

export type CreateShipmentCsvResponse = [
  {
    barcode?: string
    error?: string
  }
]

export type UserDetails = {
  emailAddress: string
  shipperName: string
}

export type ValidationFailureDetails = Array<{
  type: string
  loc: Array<string>
  msg: string
}>

export const AUTH_TOKEN_KEY = 'shipper_portal_session_token'
