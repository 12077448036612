import { Button, Modal, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import courierImg from '../../../../images/courier.png'
import { useOrderPageContext } from '../../OrderPageContext'
import { SafePlace as SafePlaceType } from '../../types'
import ModalRelayLogo from '../shared/ModalRelayLogo'
import fallbackSafePlaceList, {
  NEIGHBOUR_ID,
  neighbourMaxCharacterLimit,
} from './safePlaceList'
import SafePlaceListRadioGroup from './SafePlaceListRadioGroup'
import useSafePlace from './useSafePlace'

const COURIER_IMAGE_SIZE = 240
const StyledCourierImg = styled.img`
  margin-top: 24px;
  width: ${COURIER_IMAGE_SIZE}px;
  height: ${COURIER_IMAGE_SIZE}px;
`

const LOCAL_STORAGE_KEY = 'relay-customer-portal-tiktok-safe-place-modal'

export const tiktok = 'tiktok'

/**
 * Prompt user to add safe place if not already.
 * This is created mainly for tiktok
 */
function SafePlaceModal() {
  const {
    orderData: {
      trackingNumber,
      brandName,
      safePlacePreference,
      safePlacesList: safePlacesListFromBE,
    },
  } = useOrderPageContext()
  const {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
  } = useSafePlace()

  const [showModal, setShowModal] = useState<boolean>(false)

  const isTiktokWithoutSafePlace =
    brandName?.toLocaleLowerCase() === tiktok && !safePlacePreference
  const localStorageKey = `${LOCAL_STORAGE_KEY}-${trackingNumber}`

  const safePlacesList = safePlacesListFromBE ?? fallbackSafePlaceList
  const isNeighbourSelected = Boolean(selectedPlace?.id === NEIGHBOUR_ID)

  const handleChangeSavePlace = (safePlaceId: string) => {
    const safePlace = safePlacesList.find(
      (place: SafePlaceType) => place.id === safePlaceId
    )
    setSelectedPlace(safePlace ?? null)
    if (safePlace?.id !== NEIGHBOUR_ID) {
      setNeighbourDoorNumber('')
    }
  }

  const handleSaveSafePlace = async () => {
    if (selectedPlace) {
      await onSaveSafePlace()
    }
  }

  useEffect(() => {
    const hasSeenModal = localStorage.getItem(localStorageKey) === '1'

    if (isTiktokWithoutSafePlace && !hasSeenModal) {
      setShowModal(true)
      localStorage.setItem(localStorageKey, '1')
    }
  }, [isTiktokWithoutSafePlace, localStorageKey])

  // must be tik tok, and doesn't have safe place saved
  if (!isTiktokWithoutSafePlace) {
    return null
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={['full', 'full', 'auto']}
      margin={'auto'}
    >
      <Modal.Content
        width={'100%'}
        height={['100%', '100%', 'auto']}
        maxHeight={['full', 'full', '700px']}
        margin={0}
        paddingTop={0}
        paddingX={['16px', '16px', '32px']}
      >
        <ModalRelayLogo />
        <Modal.CloseButton />
        <Modal.Body>
          <VStack space={8} alignItems={'center'}>
            <StyledCourierImg src={courierImg} />
            <VStack space={2} alignItems={'center'}>
              <Text
                textAlign={'center'}
                fontWeight={'semibold'}
                fontSize={'xl'}
              >
                Help us deliver your {brandName} parcel successfully
              </Text>
              <Text textAlign={'center'}>
                If you will not be home please add a safe place
              </Text>
            </VStack>

            <VStack width={'100%'}>
              <SafePlaceListRadioGroup
                selectedPlace={selectedPlace}
                safePlacesList={safePlacesList}
                loading={loading}
                neighbourDoorNumber={neighbourDoorNumber}
                onChangeSelectedSafePlace={(id: string) =>
                  handleChangeSavePlace(id)
                }
                onChangeNeighbourDoorNumber={(text: string) =>
                  setNeighbourDoorNumber(
                    text.slice(0, neighbourMaxCharacterLimit)
                  )
                }
              />

              <VStack space={3} my={4}>
                <Button
                  borderRadius={'full'}
                  _text={{
                    fontWeight: 'extraBold',
                  }}
                  alignSelf={['stretch', 'stretch']}
                  px={6}
                  onPress={handleSaveSafePlace}
                  isDisabled={
                    !selectedPlace ||
                    (isNeighbourSelected && !neighbourDoorNumber)
                  }
                  isLoading={loading}
                >
                  Add safe place
                </Button>

                <Button
                  borderRadius={'full'}
                  _text={{
                    fontWeight: 'extraBold',
                    color: 'relay.textPrimary',
                  }}
                  alignSelf={['stretch', 'stretch']}
                  px={6}
                  bgColor={'relay.gray'}
                  onPress={() => setShowModal(false)}
                  isDisabled={loading}
                >
                  I’ll be home
                </Button>
              </VStack>

              {!!error && <Text color={'danger.500'}>{error}</Text>}
            </VStack>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default SafePlaceModal
