import { ArrowBackIcon, ArrowForwardIcon, IconButton } from 'native-base'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import BUTTON_STYLES from './buttonStyles'
import useCarousel from './useCarousel'

const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
`

const NavButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

const NextButtonContainer = styled(NavButtonContainer)`
  right: -12px;
`
const PrevButtonContainer = styled(NavButtonContainer)`
  left: -12px;
`
const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

function Carousel({ children }: PropsWithChildren) {
  const totalChildren = React.Children.count(children)
  const { carouselRef, currentIndex, next, prev } = useCarousel(totalChildren)

  return (
    <CarouselContainer>
      {currentIndex !== 0 && (
        <PrevButtonContainer>
          <IconButton
            icon={<ArrowBackIcon />}
            variant={'solid'}
            onPress={() => prev()}
            {...BUTTON_STYLES}
          />
        </PrevButtonContainer>
      )}
      <SliderContainer ref={carouselRef}>{children}</SliderContainer>
      {currentIndex < totalChildren - 1 && (
        <NextButtonContainer>
          <IconButton
            icon={<ArrowForwardIcon />}
            variant={'solid'}
            onPress={() => next()}
            {...BUTTON_STYLES}
          />
        </NextButtonContainer>
      )}
    </CarouselContainer>
  )
}

export default Carousel
