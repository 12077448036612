import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = {
  courierNote: string
}

async function updateNoteToCourier(
  trackingNumber: string,
  note: string
): Promise<ResponseModel> {
  const res = await fetchApi.post<ResponseModel>(
    apiRoutes.noteToCourier(trackingNumber),
    {
      note,
    }
  )
  return {
    courierNote: res?.courierNote ?? '',
  }
}

export default updateNoteToCourier
