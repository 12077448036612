import React from 'react'
import { styled } from 'styled-components'

import RelayMotifLogo from '../../../../core/Icons/RelayMotifLogo'
import { relayColors } from '../../../../theme'

const LOGO_SIZE = 30
const LOGO_CONTAINER_WIDTH = 52

const LogoContainer = styled.div`
  width: ${LOGO_CONTAINER_WIDTH}px;
  height: ${LOGO_CONTAINER_WIDTH}px;
  border-radius: 50%;
  background-color: ${relayColors.slate};
  display: flex;
  align-items: center;
  justify-content: center;
`

/** Relay logo with a circle background */
function Logo() {
  return (
    <LogoContainer>
      <RelayMotifLogo size={LOGO_SIZE} />
    </LogoContainer>
  )
}

export default Logo
