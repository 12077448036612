import dayjs from 'dayjs'

import { useIsCollection } from '../Collection'
import { useOrderPageContext } from './OrderPageContext'
import { BadgeText, DeliveryStatusType, Event } from './types'

// we allow 7 days for customer to pick up collection
const DAYS_AVAILABLE_FOR_PICK_UP = 7

/**
 * Current status is AwaitingCustomerCollection, and the last event is also
 * the same. We get the time, in hours, left to pick up, within this 7 day
 * window.
 *
 * NOTE: this is not a long term solution as we are assuming the last event
 * is the latest event, and trying to find the "correct" status type on the
 * event. Long term wise, backend should send over these type of information
 * for FE to display.
 */
const getTimeLeftToPickUp = (
  deliveryStatus: DeliveryStatusType,
  events: Event[]
): number | null => {
  if (events.length === 0) {
    return null
  }

  const lastEvent = events[events.length - 1]
  const isAwaitingCustomerCollection =
    deliveryStatus === DeliveryStatusType.AwaitingCustomerCollection &&
    lastEvent.status === DeliveryStatusType.AwaitingCustomerCollection

  const lastEventTime = dayjs(lastEvent.timestamp)
  // should be available to the customer till the end of the 7th day (ie 23:59:59)
  const deadlineForPickUp = lastEventTime
    .add(DAYS_AVAILABLE_FOR_PICK_UP + 1, 'd')
    .startOf('day')
    .subtract(1, 's')
  const now = dayjs()
  const hoursTillDeadline = deadlineForPickUp.diff(now, 'h')

  return isAwaitingCustomerCollection && now.isBefore(deadlineForPickUp)
    ? hoursTillDeadline
    : null
}

function useBadgeText(): BadgeText | undefined {
  const {
    orderData: { deliveryStatus, events, statusBadgeText },
  } = useOrderPageContext()
  const isCollection = useIsCollection()
  const timeLeftToPickUp = getTimeLeftToPickUp(deliveryStatus, events)

  if (statusBadgeText) {
    return {
      label: statusBadgeText,
      type: 'info',
    }
  }

  if (isCollection && timeLeftToPickUp !== null) {
    return {
      label: `Only ${timeLeftToPickUp} hours left to pick up`,
      type: 'warn',
    }
  }

  return undefined
}

export default useBadgeText
