import { Box, HStack, Stack, Text, theme } from 'native-base'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useBackendFeatureFlagsContext } from '../../../../core/FeatureFlag'
import ChatIcon from '../../../../core/Icons/ChatIcon'
import thumbsDown from '../../../../images/thumbsDown.png'
import thumbsUp from '../../../../images/thumbsUp.png'
import { DeliveryStatusType } from '../../types'

const NPS_URL = 'https://www.surveymonkey.com/r/MR6W3ZM'
const RATE_DELIVERY_URL = 'https://uk.surveymonkey.com/r/VBX9T5S'

const IMAGE_SIZE_DESKTOP = 40
const IMAGE_SIZE_MOBILE = 60

const StyledImage = styled.img`
  width: ${IMAGE_SIZE_DESKTOP}px;
  @media (max-width: ${theme.breakpoints.md}px) {
    width: ${IMAGE_SIZE_MOBILE}px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin-top: 24px;
  text-decoration: none;
`

type FeedbackProps = {
  status: DeliveryStatusType
}

function Feedback({ status }: FeedbackProps) {
  const isDelivered = status === DeliveryStatusType.Delivered
  const { customerPortalSurveyType } = useBackendFeatureFlagsContext()
  const { orderId } = useParams()

  // when evaluating the feature flag, its value would be undefined
  if (!isDelivered) {
    return null
  }

  const customerPortalNpsSurvey = customerPortalSurveyType === 'nps'

  const getSurveyLink = () => {
    const surveyLink = customerPortalNpsSurvey ? NPS_URL : RATE_DELIVERY_URL
    return orderId ? `${surveyLink}?order=${orderId}` : surveyLink
  }

  return (
    <StyledLink to={getSurveyLink()} target={'_blank'}>
      <Stack
        alignItems={'center'}
        space={2}
        direction={['column', 'column', 'row']}
        flex={1}
        bgColor={'relay.darkGray'}
        padding={3}
        borderRadius={3}
      >
        {customerPortalNpsSurvey && (
          <Box marginRight={2}>
            <ChatIcon />
          </Box>
        )}
        <Text
          color={'relay.volt'}
          fontWeight={'bold'}
          fontSize={'lg'}
          textAlign={customerPortalNpsSurvey ? ['center', 'left'] : 'left'}
        >
          {customerPortalNpsSurvey
            ? 'Tell us how likely you would be to recommend Relay to a friend'
            : 'How was your delivery?'}
        </Text>
        {!customerPortalNpsSurvey && (
          <HStack
            space={[4, 4, 2]}
            flex={1}
            alignItems={'center'}
            width={'100%'}
            justifyContent={['center', 'center', 'flex-start']}
          >
            <StyledImage src={thumbsUp} alt={'Thumbs up image'} />
            <StyledImage src={thumbsDown} alt={'Thumbs down image'} />
          </HStack>
        )}
      </Stack>
    </StyledLink>
  )
}

export default Feedback
