import { useSearchParams } from 'react-router-dom'

import { useBackendFeatureFlagsContext } from '../../core/FeatureFlag'

/**
 * A temporary FE hook to gate feature until we have a feature flag
 */
function useIsCollection() {
  const [searchParams] = useSearchParams()
  const { showCustomerCollection } = useBackendFeatureFlagsContext()

  return searchParams.get('isCollection') === 'true' || showCustomerCollection
}

export default useIsCollection
