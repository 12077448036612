export const getLatLngBoundsFromCoordinates = (
  coordinates: { lat: number; lon: number }[]
) => {
  const latLngArr = coordinates.map(
    ({ lat, lon }) => new window.google.maps.LatLng({ lat, lng: lon })
  )
  const latLngBounds = new google.maps.LatLngBounds()
  latLngArr.forEach((latLng) => {
    latLngBounds.extend(latLng)
  })

  return latLngBounds
}
