import { useBreakpointValue } from 'native-base'
import React from 'react'
import styled from 'styled-components'

import RelayWordmarkLogo from '../../../../core/Icons/RelayWordmarkLogo'

const LogoContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 20px;
`

function ModalRelayLogo() {
  // do not show logo on larger screen since we already show logo in the map
  const showLogo = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  })

  return showLogo ? (
    <LogoContainer>
      <RelayWordmarkLogo size={68} />
    </LogoContainer>
  ) : null
}

export default ModalRelayLogo
