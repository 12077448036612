/**
 * Get the day in week from number to string. For example:
 * 1 for Monday, 7 for Sunday
 */
const getDayInWeek = (day: number): string => {
  switch (day) {
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thur'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
    case 7:
      return 'Sun'
    default:
      // do not crash the app because of this, but make sure to log something
      console.warn(`Cannot convert number ${day} into day of week`)
      return ''
  }
}

export default getDayInWeek
