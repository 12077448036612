import { Box, Button, Link, Text } from 'native-base'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import { apiRoutes, getBaseUrl } from '../../api/apiRoutes'
import LoadingSpinner from '../../core/LoadingSpinner'
import { downloadLabel } from './downloadLabel'
import { shipperPostFormData } from './shipperPost'
import { CreateShipmentCsvResponse } from './types'
import { withUnauthorizedRedirect } from './withUnauthorizedRedirect'

function UploadShipmentCsv() {
  const [barcodes, setBarcodes] = useState<string[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [submissionInProgress, setSubmissionInProgress] = useState(false)
  const fileUploadRef = useRef<HTMLInputElement | null>(null)

  const uploadCsv = useCallback(async () => {
    const file = fileUploadRef.current?.files?.item(0)
    if (!file) {
      return
    }
    setBarcodes([])
    setErrors([])

    const data = new FormData()
    data.append('csv_file', file)
    setSubmissionInProgress(true)
    try {
      const response = await withUnauthorizedRedirect(() =>
        shipperPostFormData<CreateShipmentCsvResponse>(
          apiRoutes.shipperPortal.createShipmentCsv(),
          data
        )
      )
      setBarcodes(
        response.map((row) => row.barcode).filter((x): x is string => !!x)
      )
      setErrors(
        response.map((row) => row.error).filter((x): x is string => !!x)
      )
    } finally {
      setSubmissionInProgress(false)
    }
  }, [])

  const templateCsvUrl = useMemo(
    () => new URL(apiRoutes.shipperPortal.getTemplateCsv(), getBaseUrl()).href,
    []
  )

  return (
    <>
      <input
        ref={fileUploadRef}
        type={'file'}
        name={'csv_file'}
        accept={'text/csv'}
        onChange={uploadCsv}
        hidden
      />
      <Button
        onPress={() => fileUploadRef.current?.click()}
        disabled={submissionInProgress}
      >
        Upload CSV
      </Button>
      <Link href={templateCsvUrl}>Download CSV template</Link>
      <Box display={submissionInProgress ? 'block' : 'none'} mt={6}>
        <LoadingSpinner
          testId={'CSVUploadSpinner'}
          accessibilityLabel={'Uploading CSV'}
          height={'auto'}
        />
      </Box>
      {barcodes.map((barcode) => (
        <Text key={barcode}>
          Created shipment {barcode}.{' '}
          <Link onPress={() => downloadLabel(barcode)}>Download label</Link>
        </Text>
      ))}
      {errors.map((error, i) => (
        <Text key={i} color={'relay.error'}>
          {error}
        </Text>
      ))}
    </>
  )
}
export default UploadShipmentCsv
