import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from './types'

function HomeIcon({ size = 28 }: IconProps) {
  return (
    <Icon size={size} viewBox={'0 0 24 22'}>
      <Path
        d={
          'M14.4707 18.9199V13.0703C14.4707 12.849 14.4023 12.6732 14.2656 12.543C14.1354 12.4128 13.9596 12.3477 13.7383 12.3477H10.252C10.0306 12.3477 9.85156 12.4128 9.71484 12.543C9.58464 12.6732 9.51953 12.849 9.51953 13.0703V18.9199H14.4707ZM3.51367 18.9199V12.2598L11.4141 5.64844C11.8047 5.3099 12.1953 5.3099 12.5859 5.64844L20.4766 12.2598V18.9199C20.4766 19.6165 20.265 20.1667 19.8418 20.5703C19.4186 20.974 18.8424 21.1758 18.1133 21.1758H5.87695C5.1543 21.1758 4.57812 20.974 4.14844 20.5703C3.72526 20.1667 3.51367 19.6165 3.51367 18.9199ZM0.378906 10.541C0.378906 10.1895 0.531901 9.88672 0.837891 9.63281L10.457 1.55664C10.9323 1.15951 11.4499 0.960938 12.0098 0.960938C12.5697 0.960938 13.0807 1.15951 13.543 1.55664L23.1621 9.62305C23.4551 9.87695 23.6016 10.1895 23.6016 10.5605C23.6016 10.8861 23.4941 11.1465 23.2793 11.3418C23.0645 11.5306 22.7943 11.625 22.4688 11.625C22.306 11.625 22.1465 11.5892 21.9902 11.5176C21.834 11.4395 21.6875 11.3451 21.5508 11.2344L12.4883 3.61719C12.332 3.48047 12.1693 3.41211 12 3.41211C11.8307 3.41211 11.6712 3.48047 11.5215 3.61719L2.43945 11.2344C2.30924 11.3451 2.16602 11.4395 2.00977 11.5176C1.85352 11.5892 1.69401 11.625 1.53125 11.625C1.17969 11.625 0.89974 11.5208 0.691406 11.3125C0.483073 11.1042 0.378906 10.847 0.378906 10.541ZM17.5371 5.64844V3.44141C17.5371 3.23307 17.6022 3.0638 17.7324 2.93359C17.8626 2.80339 18.0352 2.73828 18.25 2.73828H19.8418C20.0566 2.73828 20.2259 2.80339 20.3496 2.93359C20.4798 3.0638 20.5449 3.23307 20.5449 3.44141V8.16797L17.5371 5.64844Z'
        }
        fill={'white'}
      />
    </Icon>
  )
}

export default HomeIcon
