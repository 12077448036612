import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { relayColors } from '../../theme'
import { IconProps } from './types'

type Props = IconProps & {
  color?: string
}

function CircleCheckIcon({ size = 24, color = relayColors.volt }: Props) {
  return (
    <Icon size={`${size}px`} color={'transparent'}>
      <Path
        d={
          'M15 10L11 14L9 12M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </Icon>
  )
}

export default CircleCheckIcon
