import { ProviderConfig } from 'launchdarkly-react-client-sdk'

const launchDarklyConfig: ProviderConfig = {
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  deferInitialization: true,
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
}

export default launchDarklyConfig
