import fetchApi, { UnauthorizedError } from '../../api/fetchApi'
import { AUTH_TOKEN_KEY } from './types'

/**
 * Make a POST request authenticated by the locally-stored authentication token,
 * and return the result as a parsed JSON object with camel-cased keys.
 */
export async function shipperPost<T>(path: string, data: unknown): Promise<T> {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  if (!token) {
    throw new UnauthorizedError('No token')
  }
  return fetchApi.post<T>(path, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Make a POST request authenticated by the locally-stored authentication token,
 * and return the result as a parsed JSON object with camel-cased keys.
 */
export async function shipperPostFormData<T>(
  path: string,
  data: FormData
): Promise<T> {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  if (!token) {
    throw new UnauthorizedError('No token')
  }
  return fetchApi.postFormData<T>(path, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
