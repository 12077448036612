import { Text } from 'native-base'
import { VStack } from 'native-base'
import React from 'react'

import { Destination } from '../../types'

// only exporting for tests
export const getAddressLastLine = (
  town: string,
  county: string | null,
  postalCode: string
) => {
  let str = town ?? ''

  if (county) {
    if (str === '') {
      str += county
    } else {
      str += `, ${county}`
    }
  }

  if (postalCode) {
    if (str === '') {
      str += postalCode
    } else {
      str += `, ${postalCode}`
    }
  }

  return str
}

type DeliveryDetailsProps = {
  destination: Destination
}

function DeliveryDetails({ destination }: DeliveryDetailsProps) {
  const {
    name,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    town,
    county,
    postalCode,
  } = destination

  const addressLastLine = getAddressLastLine(town, county, postalCode)

  return (
    <VStack>
      <Text fontFamily={'body'} fontWeight={'bold'}>
        {name}
      </Text>
      {addressLine1 && (
        <Text color={'relay.textSecondary'}>{addressLine1}</Text>
      )}
      {addressLine2 && (
        <Text color={'relay.textSecondary'}>{addressLine2}</Text>
      )}
      {addressLine3 && (
        <Text color={'relay.textSecondary'}>{addressLine3}</Text>
      )}
      {addressLine4 && (
        <Text color={'relay.textSecondary'}>{addressLine4}</Text>
      )}
      {addressLastLine && (
        <Text color={'relay.textSecondary'}>{addressLastLine}</Text>
      )}
    </VStack>
  )
}

export default DeliveryDetails
