import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

function useCarousel(numOfItems: number) {
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const maxCarouselIndex = numOfItems - 1

  const next = useCallback(() => {
    setCurrentIndex((index) => (index === maxCarouselIndex ? 0 : index + 1))
  }, [maxCarouselIndex])
  const prev = useCallback(() => {
    setCurrentIndex((index) => (index === 0 ? maxCarouselIndex : index - 1))
  }, [maxCarouselIndex])

  useEffect(() => {
    if (!carouselRef.current) {
      throw new Error('Carousel element cannot be empty')
    }

    const carouselItem = carouselRef.current.children[currentIndex]
    if (!carouselItem) {
      // this should warn but not block the action
      // eslint-disable-next-line no-console
      console.warn('Cannot find carouselitem')
    } else {
      carouselItem.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      })
    }
  }, [currentIndex])

  const data = useMemo(
    () => ({
      carouselRef,
      currentIndex,
      next,
      prev,
    }),
    [currentIndex, next, prev]
  )

  return data
}

export default useCarousel
