import { useToast } from 'native-base'
import { useState } from 'react'

import updatePreferredSafePlace from '../../../../api/updatePreferredSafePlace'
import { useOrderPageContext } from '../../OrderPageContext'
import {
  SafePlace as SafePlaceType,
  SafePlaceType as SafePlaceEnum,
} from '../../types'
import { getSafePlace } from './safePlaceList'

const TOAST_ID = 'save-safe-place-toast'
const SUCCESS_TOAST_TEXT = 'Safe Place Saved!'

const useSaveSafePlace = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const {
    orderData: { trackingNumber, safePlacePreference },
    updateOrderData,
  } = useOrderPageContext()
  const toast = useToast()

  const enqueueToast = () => {
    // check isActive to avoid duplicate toast
    if (!toast.isActive(TOAST_ID)) {
      toast.show({
        id: TOAST_ID,
        placement: 'bottom',
        description: SUCCESS_TOAST_TEXT,
        accessibilityAnnouncement: SUCCESS_TOAST_TEXT,
      })
    }
  }

  const saveSafePlace = async (
    safePlaceType: SafePlaceEnum,
    neighbourDoorNumber: string | null
  ) => {
    setLoading(true)

    try {
      const { safePlacePreference } = await updatePreferredSafePlace(
        trackingNumber,
        'set',
        {
          safePlaceType,
          neighbourDoorNumber,
        }
      )
      if (safePlacePreference) {
        updateOrderData('safePlacePreference', safePlacePreference)
      } else {
        throw new Error('Cannot find safe place. Please try again.')
      }

      enqueueToast()
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    }

    setLoading(false)
  }

  const clearSafePlace = async () => {
    // if currently there's no safe place selected, don't need to attempt
    // to clear it out
    if (!safePlacePreference?.safePlaceType) {
      return
    }

    setLoading(true)

    try {
      const { safePlacePreference } = await updatePreferredSafePlace(
        trackingNumber,
        'clear'
      )
      updateOrderData('safePlacePreference', safePlacePreference)
      enqueueToast()
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    saveSafePlace,
    clearSafePlace,
  }
}

const useSafePlace = () => {
  const {
    orderData: { safePlacePreference },
  } = useOrderPageContext()
  const { loading, error, saveSafePlace, clearSafePlace } = useSaveSafePlace()
  const [selectedPlace, setSelectedPlace] = useState<SafePlaceType | null>(
    getSafePlace(safePlacePreference)
  )
  const [neighbourDoorNumber, setNeighbourDoorNumber] = useState<string>(
    safePlacePreference?.neighbourDoorNumber ?? ''
  )

  const onSaveSafePlace = async () => {
    if (selectedPlace) {
      await saveSafePlace(selectedPlace?.id, neighbourDoorNumber || null)
    }
  }

  return {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
    clearSafePlace,
  }
}

export default useSafePlace
