import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'

import HomeIcon from '../../../../core/Icons/HomeIcon'
import NoteIcon from '../../../../core/Icons/NoteIcon'
import ParcelIcon from '../../../../core/Icons/ParcelIcon'
import PitstopIcon from '../../../../core/Icons/PitstopIcon'
import Timeline from '../../../../core/Timeline'
import { useIsCollection } from '../../../Collection'
import { useOrderPageContext } from '../../OrderPageContext'
import {
  COLLECTION_SCHEDULED_STATUES,
  DeliveryStatusType,
  Destination,
  Event,
  OrderItem,
  SafePlacePreference,
} from '../../types'
import DeliveryDetails from '../DeliveryDetails'
import NoteToCourier from '../NoteToCourier'
import { useEnableSafePlace } from '../NoteToCourier/NoteToCourier'
import safePlaceList from '../NoteToCourier/safePlaceList'
import { tiktok } from '../NoteToCourier/SafePlaceModal'
import OrderDetails from '../OrderDetails'
import PhoneNumberInput from '../PhoneNumber/PhoneNumberInput'
import PickupInstructions from '../PickupInstructions'

enum ACCORDION_SECTION_IDS {
  deliveryDetails = 'deliveryDetails',
  parcelJourney = 'parcelJourney',
  safePlaceInstructions = 'safePlaceInstructions',
  orderDetails = 'orderDetails',
  pickupInstructions = 'pickupInstructions',
}

const OPEN_ACCORDION_PARAM = 'openSection'

const useGetOpenAccordionIdWithDefault = (): ACCORDION_SECTION_IDS => {
  const {
    orderData: { deliveryStatus },
  } = useOrderPageContext()
  const [searchParams] = useSearchParams()
  const openSectionFromParam = searchParams.get(OPEN_ACCORDION_PARAM)

  switch (openSectionFromParam) {
    case ACCORDION_SECTION_IDS.deliveryDetails:
      return ACCORDION_SECTION_IDS.deliveryDetails
    case ACCORDION_SECTION_IDS.parcelJourney:
      return ACCORDION_SECTION_IDS.parcelJourney
    case ACCORDION_SECTION_IDS.safePlaceInstructions:
      return ACCORDION_SECTION_IDS.safePlaceInstructions
    case ACCORDION_SECTION_IDS.orderDetails:
      return ACCORDION_SECTION_IDS.orderDetails
    case ACCORDION_SECTION_IDS.pickupInstructions:
      return ACCORDION_SECTION_IDS.pickupInstructions
    default:
      return deliveryStatus === DeliveryStatusType.Delivered
        ? ACCORDION_SECTION_IDS.parcelJourney
        : ACCORDION_SECTION_IDS.safePlaceInstructions
  }
}

function getDeliveryDetailsSection(destination?: Destination) {
  return {
    id: ACCORDION_SECTION_IDS.deliveryDetails,
    title: 'Delivery details',
    leadingIcon: <HomeIcon />,
    content: destination ? <DeliveryDetails destination={destination} /> : null,
  }
}

function getParcelJourneySection(events: Event[]) {
  return {
    id: ACCORDION_SECTION_IDS.parcelJourney,
    title: 'Parcel journey',
    leadingIcon: <ParcelIcon />,
    content: (
      <Timeline
        items={events.map(
          ({
            statusDisplaySlug,
            timestamp,
            imageUrl,
            images,
            courierNote,
          }: Event) => {
            return {
              title: statusDisplaySlug,
              subtitle: dayjs(timestamp).format('MMMM DD, HH:mm'),
              imgUrl: imageUrl,
              images,
              description: courierNote,
            }
          }
        )}
      />
    ),
  }
}

function getSafePlaceSection(
  enableSafePlace: boolean,
  safePlacePreference?: SafePlacePreference,
  courierNote?: string | null,
  isFeatured?: boolean
) {
  const getSafePlaceSubtitle = () => {
    if (!enableSafePlace) {
      return ''
    }

    const safePlaceLabel = safePlaceList.find(
      (safePlace) => safePlace.id === safePlacePreference?.safePlaceType
    )?.label

    return safePlaceLabel ?? 'Add your desired safe place'
  }

  return {
    id: ACCORDION_SECTION_IDS.safePlaceInstructions,
    title: 'Delivery instructions',
    subtitle: getSafePlaceSubtitle(),
    leadingIcon: <NoteIcon />,
    content: (
      <>
        <NoteToCourier note={courierNote} />
        <PhoneNumberInput />
      </>
    ),
    isFeatured,
  }
}

function getPickUpInstructionsSection() {
  return {
    id: ACCORDION_SECTION_IDS.pickupInstructions,
    title: 'Pickup Instructions',
    leadingIcon: <PitstopIcon />,
    content: <PickupInstructions />,
  }
}

export type AccordionSection = {
  /** Mainly for the `key` prop when mapping from an array */
  id: string
  /** The text displayed on the accordion header */
  title: string
  /** The text displayed below the title */
  subtitle?: string
  /** THe icon displayed before the title */
  leadingIcon: ReactNode
  /** The elements displayed after the accordion is opened */
  content: ReactNode
  isFeatured?: boolean
}

function useGetAccordionSections(): {
  sections: AccordionSection[]
  defaultOpenIndex: number[]
} {
  const {
    orderData: {
      destination,
      events,
      courierNote,
      orderDetails,
      safePlacePreference,
      deliveryStatus,
      brandName,
    },
  } = useOrderPageContext()
  const enableSafePlace = useEnableSafePlace()
  const openAccordionId = useGetOpenAccordionIdWithDefault()

  const isTiktokWithoutSafePlace =
    brandName === tiktok &&
    !safePlacePreference &&
    deliveryStatus !== DeliveryStatusType.Delivered

  const isCollection = useIsCollection()

  if (isCollection) {
    return getCollectionSections({
      destination,
      orderDetails,
      deliveryStatus,
      events,
      enableSafePlace,
      safePlacePreference,
      courierNote,
      openAccordionId,
      brandName,
    })
  }

  const sections: AccordionSection[] = [
    getDeliveryDetailsSection(destination),
    getSafePlaceSection(
      enableSafePlace,
      safePlacePreference,
      courierNote,
      isTiktokWithoutSafePlace
    ),
    getParcelJourneySection(events),
  ]

  if (orderDetails) {
    const orderDetailsSection: AccordionSection = {
      id: ACCORDION_SECTION_IDS.orderDetails,
      title: 'Order details',
      leadingIcon: <ParcelIcon />,
      content: <OrderDetails orderDetails={orderDetails} />,
    }
    sections.push(orderDetailsSection)
  }

  const defaultOpenIndex = sections.findIndex(
    (section) => section.id === openAccordionId
  )
  return { sections, defaultOpenIndex: [defaultOpenIndex || 0] }
}

function getCollectionSections({
  destination,
  orderDetails,
  deliveryStatus,
  events,
  enableSafePlace,
  safePlacePreference,
  courierNote,
  openAccordionId,
  brandName,
}: {
  destination?: Destination
  orderDetails?: OrderItem[]
  deliveryStatus: DeliveryStatusType
  events: Event[]
  enableSafePlace: boolean
  safePlacePreference?: SafePlacePreference
  courierNote?: string | null
  openAccordionId: ACCORDION_SECTION_IDS
  brandName?: string | null
}): {
  sections: AccordionSection[]
  defaultOpenIndex: number[]
} {
  const deliveryDetailsSection = {
    ...getDeliveryDetailsSection(destination),
    content: (
      <>
        {destination ? <DeliveryDetails destination={destination} /> : null}
        {orderDetails && <OrderDetails orderDetails={orderDetails} />}
      </>
    ),
  }
  const pickupInstructionsSection = getPickUpInstructionsSection()
  const parcelJourneySection: AccordionSection = getParcelJourneySection(events)

  const isTiktokWithoutSafePlace =
    brandName?.toLowerCase() === tiktok &&
    !safePlacePreference &&
    deliveryStatus !== DeliveryStatusType.Delivered
  const safePlaceInstructionsSection: AccordionSection = getSafePlaceSection(
    enableSafePlace,
    safePlacePreference,
    courierNote,
    isTiktokWithoutSafePlace
  )

  let sections: AccordionSection[] = []
  if (COLLECTION_SCHEDULED_STATUES.includes(deliveryStatus)) {
    sections = [
      pickupInstructionsSection,
      parcelJourneySection,
      deliveryDetailsSection,
    ]
  } else {
    sections = [
      safePlaceInstructionsSection,
      parcelJourneySection,
      deliveryDetailsSection,
    ]
  }

  const defaultOpenIndex = sections.findIndex(
    (section) => section.id === openAccordionId
  )

  return { sections, defaultOpenIndex: [defaultOpenIndex || 0] }
}

export default useGetAccordionSections
