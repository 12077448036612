import { Accordion as NativeBaseAccordion } from 'native-base'
import React from 'react'

import AccordionItem from './AccordionItem'
import useGetAccordionSections, {
  AccordionSection,
} from './getAccordionSections'

function Accordion() {
  const { sections, defaultOpenIndex } = useGetAccordionSections()

  return (
    <NativeBaseAccordion
      bgColor={'none'}
      borderWidth={0}
      defaultIndex={defaultOpenIndex}
    >
      {sections.map(
        (
          {
            id,
            title,
            subtitle,
            leadingIcon,
            content,
            isFeatured,
          }: AccordionSection,
          index: number
        ) => (
          <AccordionItem
            key={id}
            index={index}
            title={title}
            subtitle={subtitle}
            leadingIcon={leadingIcon}
            isFeatured={isFeatured}
          >
            {content}
          </AccordionItem>
        )
      )}
    </NativeBaseAccordion>
  )
}

export default Accordion
