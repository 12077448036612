import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { OrderData } from './types'

type OrderDataValueType = OrderData[keyof OrderData]
type UpdateOrderDataFn = (
  key: keyof OrderData,
  value: OrderDataValueType
) => void

type OrderPageContextState = {
  orderData: OrderData
  updateOrderData: UpdateOrderDataFn
}
const OrderPageContext = createContext<OrderPageContextState>({
  orderData: {} as OrderData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateOrderData: () => {},
})

export const OrderPageContextProvider: FC<
  PropsWithChildren<Omit<OrderPageContextState, 'updateOrderData'>>
> = ({ orderData: orderDataProp, children }) => {
  const [orderData, setOrderData] = useState<OrderData>(orderDataProp)

  const updateOrderData: UpdateOrderDataFn = (key, value) => {
    setOrderData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  useEffect(() => {
    setOrderData(orderDataProp)
  }, [orderDataProp])

  return (
    <OrderPageContext.Provider value={{ orderData, updateOrderData }}>
      {children}
    </OrderPageContext.Provider>
  )
}

export const useOrderPageContext = () => {
  const context = useContext(OrderPageContext)

  if (!context) {
    throw new Error(
      'useOrderPageContext must be used within OrderPageContextProvider'
    )
  }

  return context
}
