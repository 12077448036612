import { IconButton, Input, SearchIcon } from 'native-base'
import React, { useEffect, useRef } from 'react'

type SearchInputProps = {
  value: string
  onChangeText: (value: string) => void
  onPressSearch: () => void
}

function SearchInput({ value, onChangeText, onPressSearch }: SearchInputProps) {
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    // auto focus on load
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Input
      ref={ref}
      type={'text'}
      value={value}
      variant={'filled'}
      w={'100%'}
      size={'lg'}
      onChangeText={onChangeText}
      placeholder={'Type in the zip code, for example: W1B 5AH'}
      _hover={{
        borderColor: 'relay.volt',
      }}
      _focus={{
        borderColor: 'relay.volt',
        _hover: { borderColor: 'relay.volt' },
      }}
      mb={6}
      // when hitting enter key
      onSubmitEditing={onPressSearch}
      InputRightElement={
        <IconButton
          icon={<SearchIcon color={'coolGray.800'} />}
          variant={'solid'}
          bgColor={'relay.volt'}
          onPress={onPressSearch}
        />
      }
    />
  )
}

export default SearchInput
