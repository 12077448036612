const BUTTON_STYLES = {
  borderRadius: 'full',
  borderWidth: 1,
  borderColor: 'relay.white',
  _icon: {
    color: 'relay.white',
    bg: 'relay.darkGray',
  },
  bgColor: 'relay.darkGray',
  _hover: {
    bg: 'relay.gray',
    _icon: {
      bg: 'relay.gray',
    },
  },
  _focus: {
    bg: 'relay.gray',
    _icon: {
      bg: 'relay.gray',
      borderColor: 'relay.white',
    },
    borderColor: 'relay.white',
  },
  _pressed: {
    bg: 'relay.gray',
    _icon: {
      bg: 'relay.gray',
      borderColor: 'relay.white',
    },
    borderColor: 'relay.white',
  },
}

export default BUTTON_STYLES
