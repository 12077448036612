import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { asyncWithFeatureFlagProvider } from './core/FeatureFlag/FeatureFlag'
import reportWebVitals from './reportWebVitals'
;(async () => {
  const FeatureFlagProvider = await asyncWithFeatureFlagProvider()
  ReactDOM.render(
    <React.StrictMode>
      <FeatureFlagProvider>
        <App />
      </FeatureFlagProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
