import React, { createContext, PropsWithChildren, useContext } from 'react'

export type BackendFeatureFlags = {
  customerPortalSurveyType: string
  customerPortalSafePlaceSelectionEnabled: boolean
  customerPortalShowPhoneNumberPrompt: boolean
  showCustomerCollection: boolean
}

type BackendFeatureFlagsContextState = BackendFeatureFlags

const DEFAULT_VALUES = {
  customerPortalSurveyType: 'rate_delivery',
  customerPortalSafePlaceSelectionEnabled: false,
  customerPortalShowPhoneNumberPrompt: false,
  showCustomerCollection: false,
}

const BackendFeatureFlagsContext =
  createContext<BackendFeatureFlagsContextState>(DEFAULT_VALUES)

type BackendFeatureFlagsContextProviderProps = {
  featureFlagValues?: BackendFeatureFlags
}

export function BackendFeatureFlagsContextProvider({
  featureFlagValues,
  children,
}: PropsWithChildren<BackendFeatureFlagsContextProviderProps>) {
  if (!featureFlagValues) {
    console.error(
      'Cannot find feature flag values from backend. Serving default values.'
    )
  }

  return (
    <BackendFeatureFlagsContext.Provider
      value={featureFlagValues ?? DEFAULT_VALUES}
    >
      {children}
    </BackendFeatureFlagsContext.Provider>
  )
}

export function useBackendFeatureFlagsContext(): BackendFeatureFlags {
  const context = useContext(BackendFeatureFlagsContext)

  if (!context) {
    throw new Error(
      'useBackendFeatureFlagsContext must be used within BackendFeatureFlagsContextProvider'
    )
  }

  return context
}
