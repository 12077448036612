import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from './types'

function RelayWordmarkLogo({ size = 84 }: IconProps) {
  return (
    <Icon size={size} viewBox={'0 0 84 42'}>
      <Path
        d={
          'M18.8905 16.8435H26.4702C26.2302 15.3602 25.0548 13.8813 22.8184 13.8813C21.0634 13.8813 19.5122 14.9829 18.8905 16.8435ZM27.0918 20.981L30.7797 23.5297C29.123 26.2519 26.2663 27.7655 22.9225 27.7655C18.0332 27.7655 13.8639 23.9056 13.8639 18.7069C13.8639 13.5083 18.0318 9.64838 22.7866 9.64838C28.4392 9.64838 31.7772 14.1256 31.4317 20.1222H18.7228C19.2404 22.2589 20.9275 23.4964 22.9283 23.4964C24.9291 23.4964 26.0986 22.5307 27.0976 20.9839L27.0918 20.9781V20.981Z'
        }
        fill={'white'}
      />
      <Path d={'M39.2137 0H33.9746V27.764H39.2137V0Z'} fill={'white'} />
      <Path
        d={
          'M51.5048 23.2579C54.0174 23.2579 55.9141 21.2932 55.9141 18.7127C55.9141 16.1322 54.0174 14.1675 51.5048 14.1675C48.9922 14.1675 47.0594 16.1322 47.0594 18.7127C47.0594 21.2932 48.9503 23.2579 51.5048 23.2579ZM61.0115 27.3535H55.7767V25.2847C54.7431 26.6277 52.7784 27.7655 50.4017 27.7655C45.6845 27.7655 41.8607 23.8737 41.8607 18.7069C41.8607 13.5401 45.6845 9.64838 50.4017 9.64838C52.7784 9.64838 54.7431 10.7818 55.7767 12.1291V10.0662H61.0115V10.0604V27.3535Z'
        }
        fill={'white'}
      />
      <Path
        d={
          'M71.1312 23.9431L63.5877 10.0589H69.34L73.8837 18.6737L78.1889 10.0589H83.7359L70.0917 36H64.617L71.1254 23.9431H71.1312Z'
        }
        fill={'white'}
      />
      <Path d={'M5.255 10.4912H0V27.7713H5.255V10.4912Z'} fill={'white'} />
      <Path
        d={
          'M9.38237 17.1109C11.2116 17.1109 12.6944 15.6281 12.6944 13.7989C12.6944 11.9697 11.2116 10.4869 9.38237 10.4869C7.55319 10.4869 6.07035 11.9697 6.07035 13.7989C6.07035 15.6281 7.55319 17.1109 9.38237 17.1109Z'
        }
        fill={'white'}
      />
    </Icon>
  )
}

export default RelayWordmarkLogo
