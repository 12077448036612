import React, { createContext, FC, PropsWithChildren, useContext } from 'react'

type MapContextState = {
  map: google.maps.Map
}

const MapContext = createContext<MapContextState | null>(null)

/** Context for map instance value */
export const MapContextProvider: FC<PropsWithChildren<MapContextState>> = ({
  map,
  children,
}) => {
  return <MapContext.Provider value={{ map }}>{children}</MapContext.Provider>
}

export const useMapContext = (): MapContextState | null => {
  const context = useContext(MapContext)

  if (context === undefined) {
    // eslint-disable-next-line no-console
    console.error('useMapContext must be used with MapContextProvider')
  }

  return context
}
