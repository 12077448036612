import { Box, Spinner } from 'native-base'
import React from 'react'

function LoadingSpinner({
  accessibilityLabel,
  testId,
  height = '100vh',
}: {
  accessibilityLabel: string
  testId?: string
  height?: Parameters<typeof Box>[0]['height']
}) {
  return (
    <Box
      width={'100%'}
      height={height}
      bgColor={'relay.black'}
      alignContent={'center'}
      justifyContent={'center'}
    >
      <Spinner
        testID={testId}
        accessibilityLabel={accessibilityLabel}
        size={'lg'}
        color={'relay.volt'}
      />
    </Box>
  )
}

export default LoadingSpinner
