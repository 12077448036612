import { Modal, Text } from 'native-base'
import { Image } from 'native-base'
import { Box, HStack, VStack } from 'native-base'
import React, { useState } from 'react'
import { styled } from 'styled-components'

import { relayColors } from '../../theme'
import TimelineImages from './TimelineImages'

const TIMELINE_ELLIPSE_SIZE = 12
const TIMELINE_VERTICAL_LINE_WIDTH = 2

const TimelineEllipse = styled.div`
  width: ${TIMELINE_ELLIPSE_SIZE}px;
  height: ${TIMELINE_ELLIPSE_SIZE}px;
  border-radius: 50%;
  background-color: ${relayColors.volt};
`
const TimelineVerticalLine = styled.div`
  width: ${TIMELINE_VERTICAL_LINE_WIDTH}px;
  background: red;
  position: absolute;
  height: calc(100% - ${TIMELINE_ELLIPSE_SIZE}px);
  left: ${TIMELINE_ELLIPSE_SIZE / 2 - TIMELINE_VERTICAL_LINE_WIDTH / 2}px;
  background-color: ${relayColors.slate};
`

const TimelineContentContainer = styled.button`
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 360px) {
    flex-direction: column;
  }
`

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalImageContainer = styled.div`
  width: 100%;

  img {
    max-width: 100%;
  }
`

const NUM_OF_PREVIEW_IMAGES = 2
const PREVIEW_IMAGE_SIZE = 42

export type TimelineItemType = {
  title: string
  subtitle: string
  /** @deprecated use images instead */
  imgUrl: string | null
  /** A list of image urls for the event */
  images?: string[] | null
  description?: string | null
  isLast?: boolean
}

function TimelineItem({
  title,
  subtitle,
  description,
  imgUrl,
  images,
  isLast,
}: TimelineItemType) {
  const [showModal, setShowModal] = useState<boolean>(false)

  const imagesArr = images ?? []
  const shouldShowImageArray = imagesArr.length > 0
  const thumbnailImage = shouldShowImageArray ? images?.[0] : imgUrl

  const renderPreviewImage = (image: string) => (
    <Box key={image} size={PREVIEW_IMAGE_SIZE}>
      <Image
        testID={'TimelineItemImage'}
        source={{
          uri: image,
        }}
        alt={title}
        size={10}
        borderRadius={8}
      />
    </Box>
  )

  const renderPreviewImages = () => {
    if (shouldShowImageArray) {
      // we only show up to X number of images for preview
      const imagesToPreview =
        imagesArr.length > NUM_OF_PREVIEW_IMAGES
          ? imagesArr.slice(0, NUM_OF_PREVIEW_IMAGES)
          : imagesArr

      // indicate that there are X more images available that are not shown
      // in preview
      const numAbovePreviewLimit = imagesArr.length - NUM_OF_PREVIEW_IMAGES

      return (
        <HStack space={1}>
          {imagesToPreview.map((image: string) => renderPreviewImage(image))}
          {numAbovePreviewLimit > 0 && (
            <Box
              testID={'MoreImageMessagingTitle'}
              size={PREVIEW_IMAGE_SIZE}
              justifyContent={'center'}
              alignItems={'center'}
              bgColor={'relay.gray'}
              borderRadius={8}
            >
              <Text>{`+${numAbovePreviewLimit}`}</Text>
            </Box>
          )}
        </HStack>
      )
    }

    return thumbnailImage ? renderPreviewImage(thumbnailImage) : null
  }

  return (
    <HStack testID={'TimelineItem'}>
      <Box display={'block'}>
        <Box marginRight={6} marginTop={1}>
          <TimelineEllipse data-testid={'TimelineEllipse'} />
        </Box>
        {!isLast && (
          <TimelineVerticalLine data-testid={'TimelineVerticalLine'} />
        )}
      </Box>
      <TimelineContentContainer
        onClick={() => thumbnailImage && setShowModal(true)}
      >
        <VStack
          flex={'1'}
          marginBottom={isLast ? 0 : 5}
          justifyContent={'flex-start'}
          textAlign={'left'}
        >
          <Text lineHeight={'md'} fontWeight={'bold'}>
            {title}
          </Text>
          <Text color={'relay.textSecondary'}>{subtitle}</Text>
          <Text mt={1}>{description}</Text>
        </VStack>
        {renderPreviewImages()}
      </TimelineContentContainer>
      {thumbnailImage && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          size={'xl'}
          _overlay={{
            style: {
              height: '100%',
              width: '100%',
              // somehow native base doesn't allow for fixed position but it
              // DOES take it ¯\_(ツ)_/¯
              position: 'fixed',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
          }}
        >
          <ModalContainer>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Body>
                <ModalImageContainer data-testid={'ModalImageContainer'}>
                  <TimelineImages
                    images={
                      shouldShowImageArray && images ? images : [thumbnailImage]
                    }
                  />
                </ModalImageContainer>
              </Modal.Body>
            </Modal.Content>
          </ModalContainer>
        </Modal>
      )}
    </HStack>
  )
}

export default TimelineItem
