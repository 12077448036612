import { Input, Radio, Text, VStack } from 'native-base'
import React from 'react'

import { SafePlace as SafePlaceType } from '../../types'
import { NEIGHBOUR_ID } from './safePlaceList'

type Props = {
  selectedPlace: SafePlaceType | null
  safePlacesList: SafePlaceType[]
  loading: boolean
  neighbourDoorNumber: string
  onChangeSelectedSafePlace: (id: string) => void
  onChangeNeighbourDoorNumber: (text: string) => void
}

function SafePlaceListRadioGroup({
  selectedPlace,
  safePlacesList,
  loading,
  neighbourDoorNumber,
  onChangeSelectedSafePlace,
  onChangeNeighbourDoorNumber,
}: Props) {
  const isNeighbourSelected = Boolean(selectedPlace?.id === NEIGHBOUR_ID)

  return (
    <>
      <Radio.Group
        name={'preferredSafePlace'}
        accessibilityLabel={'Preferred Safe Place'}
        value={selectedPlace?.id ?? ''}
        onChange={(id: string) => onChangeSelectedSafePlace(id)}
      >
        {safePlacesList.map((safePlace: SafePlaceType) => (
          <>
            <Radio
              key={safePlace.id}
              value={safePlace.id}
              // do not allow changing selection while request is in progress
              isDisabled={loading}
              my={2}
            >
              {safePlace.label}
            </Radio>
            {isNeighbourSelected && safePlace.id === NEIGHBOUR_ID && (
              <VStack alignItems={'flex-start'} mt={2} mb={5} ml={8}>
                <Text fontWeight={'light'} fontSize={'sm'}>
                  Door or flat number
                </Text>
                <Input
                  mt={1}
                  variant={'outline'}
                  isFullWidth={false}
                  value={neighbourDoorNumber}
                  onChangeText={(text: string) =>
                    onChangeNeighbourDoorNumber(text)
                  }
                  fontSize={16}
                />
              </VStack>
            )}
          </>
        ))}
      </Radio.Group>
    </>
  )
}

export default SafePlaceListRadioGroup
