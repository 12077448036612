import React from 'react'

import { useMapContext } from './MapContext'

type GoogleMapsMarkerProps = {
  position: {
    lat: number
    lon: number
  }
  icon?: {
    url: string
  }
  label?: string
}

/**
 * Light wrapper to render a marker on google maps
 */
function GoogleMapsMarker({ position, icon, label }: GoogleMapsMarkerProps) {
  const { map } = useMapContext() ?? {}

  React.useEffect(() => {
    let markerInstance: google.maps.Marker | null = null

    if (map) {
      markerInstance = new google.maps.Marker({
        map,
        position: {
          lat: position.lat,
          lng: position.lon,
        },
        label,
        ...(icon
          ? {
              icon: {
                url: icon.url,
              },
            }
          : {}),
      })
    }

    return () => {
      markerInstance?.setMap(null)
    }
  }, [map, position, icon, label])

  return null
}

export default GoogleMapsMarker
