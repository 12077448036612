import { Icon } from 'native-base'
import React from 'react'
import { Path } from 'react-native-svg'

import { relayColors } from '../../theme'
import { IconProps } from './types'

function ParcelIcon({ size = 28, color = relayColors.white }: IconProps) {
  return (
    <Icon size={size} viewBox={'0 0 22 23'}>
      <Path
        d={
          'M12.3164 22.7812V12.168L21.9521 6.80762C21.9808 6.96517 21.9951 7.15137 21.9951 7.36621V15.7236C21.9951 16.4398 21.8698 16.9876 21.6191 17.3672C21.3685 17.7396 20.9818 18.0654 20.459 18.3447L12.7139 22.6201C12.6494 22.6631 12.5814 22.6989 12.5098 22.7275C12.4453 22.7562 12.3809 22.7741 12.3164 22.7812ZM10.6836 22.7812C10.6191 22.7741 10.5511 22.7562 10.4795 22.7275C10.415 22.6989 10.3506 22.6631 10.2861 22.6201L2.53027 18.3447C2.00749 18.0654 1.62077 17.7396 1.37012 17.3672C1.11947 16.9876 0.994141 16.4398 0.994141 15.7236V7.36621C0.994141 7.15137 1.01204 6.96517 1.04785 6.80762L10.6836 12.168V22.7812ZM11.5 10.707L1.76758 5.34668C1.93229 5.18197 2.15072 5.02799 2.42285 4.88477L6.01074 2.9082L15.7861 8.34375L11.5 10.707ZM17.5264 7.39844L7.68652 1.98438L9.49121 0.985352C10.1429 0.627279 10.8125 0.448242 11.5 0.448242C12.1875 0.448242 12.8607 0.627279 13.5195 0.985352L20.5771 4.88477C20.8421 5.02799 21.0605 5.18197 21.2324 5.34668L17.5264 7.39844Z'
        }
        fill={color}
      />
    </Icon>
  )
}

export default ParcelIcon
