import { Box, Text } from 'native-base'
import React from 'react'

type NoMatchProps = {
  error?: string
}

function NoMatch({ error }: NoMatchProps) {
  return (
    <Box
      width={'100%'}
      height={'100vh'}
      alignItems={'center'}
      justifyContent={'center'}
      bgColor={'relay.black'}
    >
      <Text color={'relay.textPrimary'} fontSize={'xl'}>
        Page Not Found
      </Text>
      {error && <Text color={'relay.textSecondary'}>{error}</Text>}
    </Box>
  )
}

export default NoMatch
