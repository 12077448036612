import { Button, Text, TextArea, VStack } from 'native-base'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useBackendFeatureFlagsContext } from '../../../../core/FeatureFlag'
import { useOrderPageContext } from '../../OrderPageContext'
import SafePlace from './SafePlace'
import useUpdateNoteToCourier from './useUpdateNoteToCourier'

export const useEnableSafePlace = () => {
  const [searchParams] = useSearchParams()
  const { customerPortalSafePlaceSelectionEnabled } =
    useBackendFeatureFlagsContext()
  return (
    searchParams.get('enableSafePlace') === 'true' ||
    customerPortalSafePlaceSelectionEnabled
  )
}

type NoteToCourierProps = {
  note?: string | null
}

/**
 * Render textarea input for adding notes to courier
 */
function NoteToCourier({ note }: NoteToCourierProps) {
  const hasExistingNote = !!note

  const [localNoteVal, setLocalNoteVal] = useState<string>(note ?? '')
  const [isEditMode, setIsEditMode] = useState<boolean>(!hasExistingNote)
  const {
    orderData: { trackingNumber },
  } = useOrderPageContext()
  const enableSafePlace = useEnableSafePlace()

  const { isLoading, error, onUpdateNoteToCourier } = useUpdateNoteToCourier(
    trackingNumber,
    (note: string) => {
      setIsEditMode(false)
      setLocalNoteVal(note)
    }
  )

  React.useEffect(() => {
    if (note != null) {
      setLocalNoteVal(note)
    }
  }, [note])

  return (
    <VStack space={3}>
      {enableSafePlace && <SafePlace />}
      {enableSafePlace && (
        <Text fontWeight={'bold'}>Additional Delivery Instructions</Text>
      )}
      {isEditMode ? (
        <TextArea
          testID={'CourierNoteTextArea'}
          bgColor={'relay.gray'}
          borderWidth={0}
          h={12}
          placeholder={
            enableSafePlace
              ? 'Enter any additional delivery instructions or notes'
              : 'Enter your safe place instructions'
          }
          focusOutlineColor={'transparent'}
          value={localNoteVal}
          onChange={(e) => setLocalNoteVal(e.nativeEvent.text)}
        />
      ) : (
        <Text>{localNoteVal}</Text>
      )}
      <VStack space={2} alignItems={'center'}>
        <Button
          bgColor={'relay.gray'}
          borderRadius={'full'}
          _text={{
            fontWeight: 'extraBold',
            color: 'relay.volt',
          }}
          alignSelf={['stretch', 'stretch', 'flex-start']}
          px={6}
          isDisabled={localNoteVal.trim().length === 0}
          isLoading={isLoading}
          onPress={() => {
            if (isEditMode) {
              onUpdateNoteToCourier(localNoteVal)
            } else {
              setIsEditMode(true)
            }
          }}
        >
          {isEditMode ? 'Add instructions' : 'Change instructions'}
        </Button>
        {error && <Text color={'danger.500'}>{error}</Text>}
      </VStack>
    </VStack>
  )
}

export default NoteToCourier
