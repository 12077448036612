import dayjs from 'dayjs'
import { Box, Button, Center, HStack, Link, Text, VStack } from 'native-base'
import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import confirmCollection from '../../api/confirmCollection'
import {
  BackendFeatureFlags,
  BackendFeatureFlagsContextProvider,
} from '../../core/FeatureFlag'
import Layout from '../../core/Layout'
import LoadingSpinner from '../../core/LoadingSpinner'
import Map, { GoogleMapsMarker } from '../../core/Map'
import pinDropIcon from '../../core/Map/pinDropIcon'
import { Header } from '../Order/OrderTracking'
import { PitstopOpeningTime } from '../Order/types'
import { useGetOrderData } from '../Order/useGetShipment'
import getDayInWeek from './getDayInWeek'

function CollectionPage() {
  const { orderId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    isLoading: isLoadingOrderData,
    error: orderDataError,
    orderData,
  } = useGetOrderData()

  const {
    destination,
    pitstopUid,
    pitstopName,
    pitstopOpeningTimes,
    pitstopAddress,
    pitstopPostcode,
    pitstopGeoLat,
    pitstopGeoLong,
  } = orderData ?? {}

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const orderTrackingPageUrl = `/orders/${orderId}?${searchParams.toString()}`
  const today = dayjs().format('MMM D')
  const getMapBounds = () => {
    const bounds = []

    if (destination?.coordinates) {
      bounds.push(destination.coordinates)
    }

    if (pitstopGeoLat && pitstopGeoLong) {
      bounds.push({
        lat: pitstopGeoLat,
        lon: pitstopGeoLong,
      })
    }

    return bounds
  }

  const handleConfirmPickUp = async () => {
    if (!orderId || !pitstopUid) {
      setError('We cannot find this order order pitstop. Please try again.')
      return
    }

    setLoading(true)

    try {
      await confirmCollection(orderId, pitstopUid)
      setError('')
      navigate(orderTrackingPageUrl)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  if (isLoadingOrderData) {
    return <LoadingSpinner accessibilityLabel={'Loading the collection'} />
  }

  if (orderDataError) {
    return (
      <Center h={'100%'}>
        <Text color={'relay.volt'} textAlign={'center'}>
          {orderDataError}
        </Text>
      </Center>
    )
  }

  return (
    <BackendFeatureFlagsContextProvider
      featureFlagValues={orderData?.featureFlagValues as BackendFeatureFlags}
    >
      <Layout
        showFooter={false}
        renderMap={
          destination?.coordinates
            ? () => (
                <Box flex={['none', 'none', 1]}>
                  <Map
                    bounds={getMapBounds()}
                    shipperLogo={null}
                    brandName={null}
                    mapOptionsOverride={{
                      // since we need to see both the customer's home and the pitstop
                      // location, street level zoom is more appropriate, as opposed to
                      // the default zoom 18
                      zoom: 15,
                    }}
                  >
                    {pitstopGeoLat && pitstopGeoLong && (
                      <GoogleMapsMarker
                        position={{
                          lat: pitstopGeoLat,
                          lon: pitstopGeoLong,
                        }}
                        icon={{
                          url: pinDropIcon,
                        }}
                      />
                    )}
                  </Map>
                </Box>
              )
            : undefined
        }
      >
        <Header
          label={null}
          title={'Pick Up'}
          // it will always be ready for collection for the same day
          subtitle={`Expected from today, ${today}. We'll notify you when your parcel is ready`}
          showProgressBar={false}
        />
        <HStack
          space={[2, 2, 4]}
          alignItems={'center'}
          justifyContent={['space-between', 'space-between', 'flex-start']}
        >
          <VStack space={2}>
            <Button
              isDisabled={Boolean(loading || error)}
              isLoading={loading}
              onPress={handleConfirmPickUp}
            >
              Confirm Pick Up
            </Button>
            {!!error && (
              <Text fontSize={'sm'} color={'danger.500'}>
                {error}
              </Text>
            )}
          </VStack>
          <Link href={orderTrackingPageUrl}>Go Back</Link>
        </HStack>
        <VStack
          space={5}
          borderColor={'relay.gray'}
          borderWidth={'4px'}
          p={4}
          borderRadius={3}
          mb={8}
        >
          <VStack space={2}>
            <Text fontWeight={'bold'}>{pitstopName}</Text>
            <Text>{pitstopAddress}</Text>
            <Text>{pitstopPostcode}</Text>
            <Link
              href={`https://www.google.com/maps/search/?api=1&query=${pitstopGeoLat},${pitstopGeoLong}`}
              isExternal
            >
              Google Maps
            </Link>
          </VStack>

          {!!pitstopOpeningTimes && (
            <VStack space={2}>
              <Text fontWeight={'bold'}>Opening Hours</Text>
              {pitstopOpeningTimes.map((openingTime: PitstopOpeningTime) => {
                // check for both null and undefined
                if (openingTime.dayOfWeek == null) {
                  return null
                }

                return (
                  <Text key={openingTime.dayOfWeek}>
                    {`${getDayInWeek(openingTime.dayOfWeek)}: ${
                      openingTime.opensAt
                    } - ${openingTime.closesAt}`}
                  </Text>
                )
              })}
            </VStack>
          )}
        </VStack>
      </Layout>
    </BackendFeatureFlagsContextProvider>
  )
}

export default CollectionPage
