import { Box, Divider, HStack, Text, VStack } from 'native-base'
import React from 'react'

import { useOrderPageContext } from '../../OrderPageContext'
import { OrderItem } from '../../types'

function ItemDetail({
  itemName,
  brandName,
}: {
  itemName: string
  brandName?: string | null
}) {
  if (!itemName && !brandName) {
    return null
  }

  return brandName ? (
    <VStack flexShrink={'initial'}>
      <Text noOfLines={2} lineHeight={'sm'}>
        {brandName}
      </Text>
      <Text isTruncated lineHeight={'sm'} color={'relay.textSecondary'}>
        {itemName}
      </Text>
    </VStack>
  ) : (
    <Text noOfLines={2}>{itemName}</Text>
  )
}

type OrderDetailsProps = {
  orderDetails: OrderItem[]
}

function OrderDetails({ orderDetails }: OrderDetailsProps) {
  const {
    orderData: { trackingNumber, shipperOrderNumber, brandName },
  } = useOrderPageContext()

  return (
    <VStack space={4}>
      {shipperOrderNumber && (
        <Box>
          <Text color={'relay.textSecondary'}>{`${
            brandName ? `${brandName} ` : ''
          }Order Number`}</Text>
          <Text>{shipperOrderNumber}</Text>
        </Box>
      )}
      {trackingNumber && (
        <Box>
          <Text color={'relay.textSecondary'}>Relay Tracking Number</Text>
          <Text>{trackingNumber}</Text>
        </Box>
      )}
      {orderDetails.length > 0 && (
        <>
          <Divider />
          <Text fontWeight={'bold'}>Items</Text>
          <VStack space={2}>
            {orderDetails.map((item: OrderItem) => (
              <HStack
                key={item.name}
                space={2}
                alignItems={'center'}
                testID={'OrderItem'}
              >
                {item.quantity > 0 && (
                  <Box
                    borderRadius={8}
                    width={16}
                    height={16}
                    bgColor={'relay.slate'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    _text={{
                      fontSize: 'lg',
                      letterSpacing: '2xl',
                    }}
                  >
                    {`x${item.quantity}`}
                  </Box>
                )}
                <ItemDetail itemName={item.name} brandName={item.brandName} />
              </HStack>
            ))}
          </VStack>
        </>
      )}
    </VStack>
  )
}

export default OrderDetails
