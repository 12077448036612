import { UnauthorizedError } from '../../api/fetchApi'

export async function withUnauthorizedRedirect<T>(
  fn: () => Promise<T>
): Promise<T> {
  try {
    return await fn()
  } catch (e: unknown) {
    if (e instanceof UnauthorizedError) {
      window.location.href = '/shipper/login'
    }
    throw e
  }
}
