import React from 'react'

import {
  BackendFeatureFlags,
  BackendFeatureFlagsContextProvider,
} from '../../core/FeatureFlag'
import LoadingSpinner from '../../core/LoadingSpinner'
import NoMatch from '../NoMatch'
import { OrderPageContextProvider } from './OrderPageContext'
import ReturnOrder from './ReturnOrder'
import ShipmentOrder from './ShipmentOrder'
import { OrderType } from './types'
import { useGetOrderData } from './useGetShipment'

function OrderPage() {
  const { isLoading, orderData, error, orderType } = useGetOrderData()

  if (isLoading) {
    return (
      <LoadingSpinner
        testId={'AppLoadingSpinner'}
        accessibilityLabel={'Loading the App'}
      />
    )
  }

  if (!orderData || error) {
    return <NoMatch error={error || 'Shipment data not expected'} />
  }

  return (
    <BackendFeatureFlagsContextProvider
      featureFlagValues={orderData.featureFlagValues as BackendFeatureFlags}
    >
      <OrderPageContextProvider orderData={orderData}>
        {orderType === OrderType.Return ? (
          <ReturnOrder orderData={orderData} />
        ) : (
          <ShipmentOrder orderData={orderData} />
        )}
      </OrderPageContextProvider>
    </BackendFeatureFlagsContextProvider>
  )
}

export default OrderPage
