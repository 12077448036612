import { Icon } from 'native-base'
import React from 'react'
import { Circle, Path } from 'react-native-svg'

import { IconProps } from './types'

function LocationPinIcon({ size = 14 }: IconProps) {
  return (
    <Icon size={size} viewBox={'0 0 13 16'}>
      <mask id={'path-1-inside-1_10_222'} fill={'white'}>
        <Path
          d={
            'M1.82084 10.9918C0.951406 10.0913 0.359312 8.94399 0.119435 7.69498C-0.120441 6.44596 0.00267124 5.15133 0.473207 3.97478C0.943743 2.79824 1.74057 1.79263 2.76291 1.08512C3.78526 0.377617 4.98722 -1.43051e-05 6.21679 -1.43051e-05C7.44636 -1.43051e-05 8.64831 0.377617 9.67066 1.08513C10.693 1.79263 11.4898 2.79824 11.9604 3.97479C12.4309 5.15133 12.554 6.44596 12.3141 7.69498C12.0743 8.94399 11.4822 10.0913 10.6127 10.9918L9.68031 10.026C10.3653 9.31656 10.8318 8.41262 11.0208 7.42853C11.2098 6.44445 11.1128 5.42442 10.7421 4.49743C10.3714 3.57044 9.74356 2.77813 8.93806 2.22069C8.13256 1.66325 7.18555 1.36572 6.21679 1.36572C5.24802 1.36572 4.30101 1.66325 3.49551 2.22069C2.69001 2.77813 2.0622 3.57044 1.69147 4.49743C1.32074 5.42442 1.22374 6.44445 1.41274 7.42853C1.60174 8.41262 2.06824 9.31656 2.75326 10.026L1.82084 10.9918Z'
          }
        />
      </mask>
      <Path
        d={
          'M1.82084 10.9918C0.951406 10.0913 0.359312 8.94399 0.119435 7.69498C-0.120441 6.44596 0.00267124 5.15133 0.473207 3.97478C0.943743 2.79824 1.74057 1.79263 2.76291 1.08512C3.78526 0.377617 4.98722 -1.43051e-05 6.21679 -1.43051e-05C7.44636 -1.43051e-05 8.64831 0.377617 9.67066 1.08513C10.693 1.79263 11.4898 2.79824 11.9604 3.97479C12.4309 5.15133 12.554 6.44596 12.3141 7.69498C12.0743 8.94399 11.4822 10.0913 10.6127 10.9918L9.68031 10.026C10.3653 9.31656 10.8318 8.41262 11.0208 7.42853C11.2098 6.44445 11.1128 5.42442 10.7421 4.49743C10.3714 3.57044 9.74356 2.77813 8.93806 2.22069C8.13256 1.66325 7.18555 1.36572 6.21679 1.36572C5.24802 1.36572 4.30101 1.66325 3.49551 2.22069C2.69001 2.77813 2.0622 3.57044 1.69147 4.49743C1.32074 5.42442 1.22374 6.44445 1.41274 7.42853C1.60174 8.41262 2.06824 9.31656 2.75326 10.026L1.82084 10.9918Z'
        }
        stroke={'#D6EC83'}
        strokeWidth={'10'}
        mask={'url(#path-1-inside-1_10_222)'}
      />
      <Path
        d={
          'M6.18043 15.5765L6.21664 15.6144L6.2528 15.5764L10.6934 10.9138L10.7271 10.8785L10.6926 10.844L9.80447 9.95586L9.76867 9.92006L9.73332 9.9563L6.15358 13.6261L2.47851 9.73485L2.44318 9.69745L2.40681 9.73383L1.51869 10.6219L1.48415 10.6565L1.51788 10.6918L6.18043 15.5765Z'
        }
        fill={'#D6EC83'}
        stroke={'#D6EC83'}
        strokeWidth={'0.1'}
      />
      <Circle
        cx={'6.21654'}
        cy={'7.10445'}
        r={'1.27623'}
        fill={'#D6EC83'}
        stroke={'#D6EC83'}
      />
    </Icon>
  )
}

export default LocationPinIcon
