import { Text } from 'native-base'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ isNarrow: boolean }>`
  position: absolute;
  top: 32px;
  right: 24px;
  max-width: ${({ isNarrow }) => (isNarrow ? '80px' : '70%')};

  img {
    max-width: 100%;
  }
`

/**
 * Show shipper's logo and fallback to display the shipper's
 * name if the logo is absent
 */
function ShipperLogo({
  logo,
  name,
}: {
  logo?: string | null
  name?: string | null
}) {
  if (!logo && !name) {
    return null
  }

  return (
    <Container data-testid={'ShipperLogo'} isNarrow={!!logo}>
      {logo ? (
        <img src={logo} alt={'Shipper logo'} />
      ) : (
        <Text fontWeight={'bold'} fontSize={'xl'}>
          {name}
        </Text>
      )}
    </Container>
  )
}

export default ShipperLogo
