import { useState } from 'react'

import updatePhoneNumber from '../../../../api/updatePhoneNumber'
import { useOrderPageContext } from '../../OrderPageContext'

const useSavePhoneNumber = () => {
  const {
    orderData: { trackingNumber },
  } = useOrderPageContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const savePhoneNumber = async (
    phoneNumber: string,
    onSuccess?: (contactPhoneNumber?: string) => void
  ) => {
    setLoading(true)

    try {
      const res = await updatePhoneNumber(trackingNumber, phoneNumber)
      setError('')
      onSuccess?.(res?.contactPhoneNumber)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    setError,
    savePhoneNumber,
  }
}

export default useSavePhoneNumber
