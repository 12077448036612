import { Box } from 'native-base'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { MapContextProvider } from './MapContext'
import { getLatLngBoundsFromCoordinates } from './utils'

const HideGoogleMapsFooterStyles = createGlobalStyle`
  .gm-style-cc {
      display:none;
  }
`

type MapProps = {
  mapOptions?: google.maps.MapOptions
  styles?: {
    height?: string[]
    borderRadius?: string[]
  }
  bounds?: {
    lat: number
    lon: number
  }[]
  children?: ReactNode
}

/**
 * Renders a google map with the context
 */
function GoogleMap({ mapOptions, styles, bounds, children }: MapProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    // do not initiate more than one map
    if (ref.current && !mapInstance) {
      const map = new window.google.maps.Map(ref.current, mapOptions)
      setMapInstance(map)
    }
  }, [mapOptions, mapInstance])

  useDeepCompareEffect(() => {
    if (mapOptions && mapInstance) {
      mapInstance.setOptions(mapOptions)
    }
  }, [mapOptions])

  useEffect(() => {
    if (mapInstance && bounds && bounds.length > 0) {
      const latLngBounds = getLatLngBoundsFromCoordinates(bounds)
      mapInstance.fitBounds(latLngBounds)
    }
  }, [bounds, mapInstance])

  return (
    <>
      <Box
        ref={ref}
        maxHeight={'100%'}
        height={styles?.height}
        borderRadius={styles?.borderRadius}
      />
      {mapInstance && (
        <MapContextProvider map={mapInstance}>
          <HideGoogleMapsFooterStyles />
          {children}
        </MapContextProvider>
      )}
    </>
  )
}

export default GoogleMap
