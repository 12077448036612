import { Box } from 'native-base'
import React from 'react'
import styled from 'styled-components'

import { relayColors } from '../../theme'
import Carousel from '../Carousel'

const StyledImage = styled.img<{ $fullHeight: boolean }>`
  border-radius: 8px;
  border: 1px solid ${relayColors.gray};

  ${({ $fullHeight }) =>
    $fullHeight ? 'height: 100%; object-fit: cover;' : ''}
`

type TimelineImagesProps = {
  images: string[]
}

function TimelineImages({ images }: TimelineImagesProps) {
  const renderImage = (image: string, fullHeight: boolean) => (
    <StyledImage
      data-testid={'TimelineImageItem'}
      src={image}
      alt={''}
      $fullHeight={fullHeight}
    />
  )

  if (images.length === 0) {
    return null
  }

  if (images.length === 1) {
    return renderImage(images[0], false)
  }

  return (
    <Carousel>
      {images.map((image: string) => (
        <Box key={image} margin={[1, 2]} maxWidth={'90%'}>
          {renderImage(image, true)}
        </Box>
      ))}
    </Carousel>
  )
}

export default TimelineImages
